<template>
  <!-- Banner -->
  <div class="iq-banner">
    <div
        id="rev_slider_3_1_wrapper"
        class="rev_slider_wrapper fullwidthbanner-container"
        data-alias="marketive-1"
        style="
        margin: 0px auto;
        background-color: transparent;
        padding: 0px;
        margin-top: 0px;
        margin-bottom: 0px;
      "
    >
      <!-- START REVOLUTION SLIDER 5.2.6 fullwidth mode -->
      <div
          id="rev_slider_3_1"
          class="rev_slider fullwidthabanner tp-overflow-hidden"
          data-version="5.2.6"
          style="display: none"
      >
        <ul>
          <!-- SLIDE  -->
          <li
              data-description=""
              data-easein="default"
              data-easeout="default"
              data-hideafterloop="0"
              data-hideslideonmobile="off"
              data-index="rs-2"
              data-masterspeed="600"
              data-param1=""
              data-param10=""
              data-param2=""
              data-param3=""
              data-param4=""
              data-param5=""
              data-param6=""
              data-param7=""
              data-param8=""
              data-param9=""
              data-rotate="0"
              data-saveperformance="off"
              data-slotamount="default"
              data-title="Slide"
              data-transition="fade"
          >
            <!-- MAIN IMAGE -->
            <img
                alt=""
                class="rev-slidebg"
                data-bgfit="cover"
                data-bgparallax="off"
                data-bgposition="center center"
                data-bgrepeat="no-repeat"
                data-no-retina
                src="/assets/revslider/assets/02f6c-bg.jpg"
            />
            <!-- LAYERS -->
            <!-- LAYER NR. 1 -->
            <h1
                id="slide-2-layer-14"
                class="tp-caption tp-resizeme"
                data-height="['auto']"
                data-responsive_offset="on"
                data-splitin="none"
                data-splitout="none"
                data-start="500"
                data-transform_idle="o:1;"
                data-transform_in="x:50px;opacity:0;s:1000;e:Power4.easeInOut;"
                data-transform_out="opacity:0;s:300;"
                data-voffset="-105"
                data-width="['auto']"
                data-x="29"
                data-y="center"
                style="
                z-index: 5;
                white-space: nowrap;
                font-size: 60px;
                line-height: 70px;
                font-weight: 700;
                color: rgba(27, 14, 61, 1);
                font-family: Nunito;
              "
            >
              La carte de l'AVENIR
            </h1>
            <!-- LAYER NR. 2 -->
            <p
                id="slide-2-layer-2"
                class="tp-caption tp-resizeme"
                data-height="['auto']"
                data-responsive_offset="on"
                data-splitin="none"
                data-splitout="none"
                data-start="500"
                data-transform_idle="o:1;"
                data-transform_in="x:50px;opacity:0;s:1000;e:Power2.easeInOut;"
                data-transform_out="opacity:0;s:300;"
                data-voffset="22"
                data-width="['auto']"
                data-x="30"
                data-y="center"
                style="
                z-index: 6;
                white-space: nowrap;
                font-size: 18px;
                line-height: 32px;
                font-weight: 400;
                color: rgba(134, 136, 148, 1);
                font-family: Poppins;
              "
            >
              Passez maintenant à la SMARTCARD, <br/>
              votre carte de visite ultime pour tous vos besoins.
            </p>
            <!-- LAYER NR. 3 -->
            <a href="/create-card">
              <div
                  id="slide-2-layer-4"
                  class="tp-caption rev-btn button button-sm rev-withicon btn-slide tp-resizeme rs-hover-ready"
                  data-height="['auto']"
                  data-responsive_offset="on"
                  data-splitin="none"
                  data-splitout="none"
                  data-start="500"
                  data-style_hover="c:rgba(255, 255, 255, 1.00);bg:rgba(124, 96, 213, 1.00);"
                  data-transform_hover="o:1;rX:0;rY:0;rZ:0;z:0;s:0;e:Linear.easeNone;"
                  data-transform_idle="o:1;"
                  data-transform_in="x:50px;opacity:0;s:1000;e:Power4.easeOut;"
                  data-transform_out="opacity:0;s:300;e:Back.easeIn;"
                  data-width="['auto']"
                  data-x="29"
                  data-y="480"
                  style="
                  z-index: 7;
                  white-space: nowrap;
                  font-size: 18px;
                  line-height: 50px;
                  height: auto;
                  font-weight: 300;
                  color: rgba(255, 255, 255, 1);
                  font-family: Nunito;
                  padding: 0px 35px 0px 35px;
                  border-color: rgba(0, 0, 0, 1);
                  border-radius: 30px 30px 30px 30px;
                  outline: none;
                  box-shadow: none;
                  box-sizing: border-box;
                  -moz-box-sizing: border-box;
                  -webkit-box-sizing: border-box;
                  cursor: pointer;
                "
              >
                Commander Maintenant
              </div>
            </a
            >
            <!-- LAYER NR. 4 -->
            <span
                id="slide-2-layer-13"
                class="tp-caption tp-resizeme"
                data-height="['auto']"
                data-responsive_offset="on"
                data-splitin="none"
                data-splitout="none"
                data-start="500"
                data-transform_idle="o:1;"
                data-transform_in="x:50px;opacity:0;s:1000;e:Power2.easeInOut;"
                data-transform_out="opacity:0;s:300;"
                data-width="['auto']"
                data-x="31"
                data-y="538"
                style="
                z-index: 8;
                white-space: nowrap;
                font-size: 12px;
                line-height: 32px;
                font-weight: 400;
                color: rgba(134, 136, 148, 1);
                font-family: Poppins;
              "
            >
            </span>
            <!-- LAYER NR. 5 -->
            <div
                id="slide-2-layer-5"
                class="tp-caption tp-resizeme"
                data-height="['none','none','none','none']"
                data-responsive_offset="on"
                data-start="1020"
                data-transform_idle="o:1;"
                data-transform_in="z:0;rX:0;rY:0;rZ:0;sX:0.8;sY:0.8;skX:0;skY:0;opacity:0;s:1000;e:Power4.easeOut;"
                data-transform_out="opacity:0;s:300;"
                data-width="['none','none','none','none']"
                data-x="727"
                data-y="166"
                style="z-index: 9"
            >
              <img
                  alt=""
                  data-hh="411px"
                  data-no-retina
                  data-ww="636px"
                  src="/assets/revslider/assets/edad9-08.png"
              />
            </div>
            <!-- LAYER NR. 6 -->
            <!-- LAYER NR. 7 -->
            <div
                id="slide-2-layer-8"
                class="tp-caption tp-resizeme"
                data-height="['none','none','none','none']"
                data-responsive_offset="on"
                data-start="1360"
                data-transform_idle="o:1;"
                data-transform_in="z:0;rX:0;rY:0;rZ:0;sX:0.8;sY:0.8;skX:0;skY:0;opacity:0;s:1000;e:Power4.easeOut;"
                data-transform_out="opacity:0;s:300;e:Back.easeIn;"
                data-width="['none','none','none','none']"
                data-x="1045"
                data-y="235"
                style="z-index: 11"
            >
              <img
                  alt=""
                  data-hh="auto"
                  data-no-retina
                  data-ww="auto"
                  src="/assets/revslider/assets/77014-05.png"
              />
            </div>
            <!-- LAYER NR. 8 -->
            <!-- LAYER NR. 9 -->
            <div
                id="slide-2-layer-17"
                class="tp-caption tp-resizeme"
                data-height="['none','none','none','none']"
                data-responsive_offset="on"
                data-start="2620"
                data-transform_idle="o:1;"
                data-transform_in="x:-50px;opacity:0;s:1000;e:Power2.easeInOut;"
                data-transform_out="opacity:0;s:300;"
                data-width="['none','none','none','none']"
                data-x="858"
                data-y="330"
                style="z-index: 13"
            >
              <img
                  alt=""
                  data-hh="auto"
                  data-no-retina
                  data-ww="auto"
                  src="/assets/revslider/assets/b2422-10.png"
              />
            </div>
            <!-- LAYER NR. 10 -->
            <div
                id="slide-2-layer-15"
                class="tp-caption tp-resizeme"
                data-height="['none','none','none','none']"
                data-responsive_offset="on"
                data-start="1880"
                data-transform_idle="o:1;"
                data-transform_in="y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;s:1000;e:Power4.easeInOut;"
                data-transform_out="opacity:0;s:300;"
                data-width="['none','none','none','none']"
                data-x="798"
                data-y="491"
                style="z-index: 14"
            >
              <img
                  alt=""
                  data-hh="250px"
                  data-no-retina
                  data-ww="537px"
                  src="/assets/revslider/assets/bc86e-12.png"
              />
            </div>
            <!-- LAYER NR. 11 -->
            <div
                id="slide-2-layer-16"
                class="tp-caption tp-resizeme"
                data-height="['none','none','none','none']"
                data-responsive_offset="on"
                data-start="2360"
                data-transform_idle="o:1;"
                data-transform_in="x:50px;opacity:0;s:1000;e:Power2.easeInOut;"
                data-transform_out="opacity:0;s:300;"
                data-width="['none','none','none','none']"
                data-x="1128"
                data-y="353"
                style="z-index: 15"
            >
              <img
                  alt=""
                  data-hh="auto"
                  data-no-retina
                  data-ww="auto"
                  src="/assets/revslider/assets/b1482-09.png"
              />
            </div>
            <!-- LAYER NR. 12 -->
            <div
                id="slide-2-layer-18"
                class="tp-caption tp-resizeme"
                data-height="['none','none','none','none']"
                data-responsive_offset="on"
                data-start="2750"
                data-transform_idle="o:1;"
                data-transform_in="x:-50px;opacity:0;s:1000;e:Power2.easeInOut;"
                data-transform_out="opacity:0;s:300;"
                data-width="['none','none','none','none']"
                data-x="764"
                data-y="342"
                style="z-index: 16"
            >
              <img
                  alt=""
                  data-hh="auto"
                  data-no-retina
                  data-ww="auto"
                  src="/assets/revslider/assets/b33c3-11.png"
              />
            </div>
          </li>
          <!-- SLIDE  -->
          <li
              data-description=""
              data-easein="default"
              data-easeout="default"
              data-hideafterloop="0"
              data-hideslideonmobile="off"
              data-index="rs-3"
              data-masterspeed="300"
              data-param1=""
              data-param10=""
              data-param2=""
              data-param3=""
              data-param4=""
              data-param5=""
              data-param6=""
              data-param7=""
              data-param8=""
              data-param9=""
              data-rotate="0"
              data-saveperformance="off"
              data-slotamount="default"
              data-title="Slide"
              data-transition="fade"
          >
            <!-- MAIN IMAGE -->
            <img
                alt=""
                class="rev-slidebg"
                data-bgfit="cover"
                data-bgparallax="off"
                data-bgposition="center center"
                data-bgrepeat="no-repeat"
                data-no-retina
                src="/assets/revslider/assets/02f6c-bg.jpg"
            />
            <!-- LAYERS -->
            <!-- LAYER NR. 1 -->
            <h1
                id="slide-3-layer-14"
                class="tp-caption tp-resizeme"
                data-height="['auto']"
                data-responsive_offset="on"
                data-splitin="none"
                data-splitout="none"
                data-start="500"
                data-transform_idle="o:1;"
                data-transform_in="x:50px;opacity:0;s:1000;e:Power4.easeInOut;"
                data-transform_out="opacity:0;s:300;"
                data-width="['auto']"
                data-x="31"
                data-y="221"
                style="
                z-index: 5;
                white-space: nowrap;
                font-size: 60px;
                line-height: 70px;
                font-weight: 700;
                color: rgba(27, 14, 61, 1);
                font-family: Nunito;
              "
            >
              Rapide, Pratique <br/>
              et efficace
            </h1>
            <!-- LAYER NR. 2 -->
            <p
                id="slide-3-layer-2"
                class="tp-caption tp-resizeme"
                data-height="['auto']"
                data-responsive_offset="on"
                data-splitin="none"
                data-splitout="none"
                data-start="500"
                data-transform_idle="o:1;"
                data-transform_in="x:50px;opacity:0;s:1000;e:Power2.easeInOut;"
                data-transform_out="opacity:0;s:300;"
                data-width="['auto']"
                data-x="35"
                data-y="394"
                style="
                z-index: 6;
                white-space: nowrap;
                font-size: 18px;
                line-height: 32px;
                font-weight: 400;
                color: rgba(134, 136, 148, 1);
                font-family: Poppins;
              "
            >
              Un moyen très simple de partager vos contacts. <br/>
              Il suffit juste de scanner le QR CODE sur votre carte.
            </p>
            <!-- LAYER NR. 3 -->
            <a href="/create-card">
              <div
                  id="slide-3-layer-4"
                  class="tp-caption rev-btn button button-sm rev-withicon btn-slide tp-resizeme rs-hover-ready"
                  data-height="['auto']"
                  data-responsive_offset="on"
                  data-splitin="none"
                  data-splitout="none"
                  data-start="500"
                  data-style_hover="c:rgba(255, 255, 255, 1.00);bg:rgba(124, 96, 213, 1.00);"
                  data-transform_hover="o:1;rX:0;rY:0;rZ:0;z:0;s:0;e:Linear.easeNone;"
                  data-transform_idle="o:1;"
                  data-transform_in="x:50px;opacity:0;s:1000;e:Power4.easeOut;"
                  data-transform_out="opacity:0;s:300;e:Back.easeIn;"
                  data-width="['auto']"
                  data-x="33"
                  data-y="489"
                  style="
                  z-index: 7;
                  white-space: nowrap;
                  font-size: 18px;
                  line-height: 50px;
                  height: auto;
                  font-weight: 300;
                  color: rgba(255, 255, 255, 1);
                  font-family: Nunito;
                  padding: 0 35px 0 35px;
                  border-color: rgba(0, 0, 0, 1);
                  border-radius: 30px 30px 30px 30px;
                  outline: none;
                  box-shadow: none;
                  box-sizing: border-box;
                  -moz-box-sizing: border-box;
                  -webkit-box-sizing: border-box;
                  cursor: pointer;
                "
              >
                Commander Maintenant
              </div>
            </a
            >
            <!-- LAYER NR. 4 -->
            <!-- LAYER NR. 5 -->
            <div
                id="slide-3-layer-15"
                class="tp-caption tp-resizeme"
                data-height="['none','none','none','none']"
                data-responsive_offset="on"
                data-start="1320"
                data-transform_idle="o:1;"
                data-transform_in="z:0;rX:0;rY:0;rZ:0;sX:0.8;sY:0.8;skX:0;skY:0;opacity:0;s:1000;e:Power4.easeOut;"
                data-transform_out="opacity:0;s:300;"
                data-width="['none','none','none','none']"
                data-x="696"
                data-y="166"
                style="z-index: 9"
            >
              <img
                  alt=""
                  data-hh="449px"
                  data-no-retina
                  data-ww="607px"
                  src="/assets/revslider/assets/618b3-09.png"
              />
            </div>
            <!-- LAYER NR. 6 -->
            <div
                id="slide-3-layer-18"
                class="tp-caption tp-resizeme"
                data-height="['none','none','none','none']"
                data-responsive_offset="on"
                data-start="1720"
                data-transform_idle="o:1;"
                data-transform_in="z:0;rX:0;rY:0;rZ:0;sX:0.8;sY:0.8;skX:0;skY:0;opacity:0;s:1000;e:Power4.easeOut;"
                data-transform_out="opacity:0;s:300;"
                data-width="['none','none','none','none']"
                data-x="746"
                data-y="213"
                style="z-index: 10"
            >
              <img
                  alt=""
                  data-hh="auto"
                  data-no-retina
                  data-ww="auto"
                  src="/assets/revslider/assets/46e39-02.png"
              />
            </div>
            <!-- LAYER NR. 7 -->
            <div
                id="slide-3-layer-16"
                class="tp-caption tp-resizeme"
                data-height="['none','none','none','none']"
                data-responsive_offset="on"
                data-start="1920"
                data-transform_idle="o:1;"
                data-transform_in="z:0;rX:0;rY:0;rZ:0;sX:0.8;sY:0.8;skX:0;skY:0;opacity:0;s:1000;e:Power4.easeOut;"
                data-transform_out="opacity:0;s:300;"
                data-width="['none','none','none','none']"
                data-x="1147"
                data-y="382"
                style="z-index: 11"
            >
              <img
                  alt=""
                  data-hh="auto"
                  data-no-retina
                  data-ww="auto"
                  src="/assets/revslider/assets/ca039-06.png"
              />
            </div>
            <!-- LAYER NR. 8 -->
            <div
                id="slide-3-layer-17"
                class="tp-caption tp-resizeme"
                data-height="['none','none','none','none']"
                data-responsive_offset="on"
                data-start="1920"
                data-transform_idle="o:1;"
                data-transform_in="z:0;rX:0;rY:0;rZ:0;sX:0.8;sY:0.8;skX:0;skY:0;opacity:0;s:1000;e:Power4.easeOut;"
                data-transform_out="opacity:0;s:300;"
                data-width="['none','none','none','none']"
                data-x="750"
                data-y="384"
                style="z-index: 12"
            >
              <img
                  alt=""
                  data-hh="auto"
                  data-no-retina
                  data-ww="auto"
                  src="/assets/revslider/assets/46281-03.png"
              />
            </div>
            <!-- LAYER NR. 9 -->
            <div
                id="slide-3-layer-19"
                class="tp-caption tp-resizeme"
                data-height="['none','none','none','none']"
                data-responsive_offset="on"
                data-start="1920"
                data-transform_idle="o:1;"
                data-transform_in="z:0;rX:0;rY:0;rZ:0;sX:0.8;sY:0.8;skX:0;skY:0;opacity:0;s:1000;e:Power4.easeOut;"
                data-transform_out="opacity:0;s:300;"
                data-width="['none','none','none','none']"
                data-x="881"
                data-y="383"
                style="z-index: 13"
            >
              <img
                  alt=""
                  data-hh="auto"
                  data-no-retina
                  data-ww="auto"
                  src="/assets/revslider/assets/ca039-06.png"
              />
            </div>
            <!-- LAYER NR. 10 -->
            <div
                id="slide-3-layer-20"
                class="tp-caption tp-resizeme"
                data-height="['none','none','none','none']"
                data-responsive_offset="on"
                data-start="1920"
                data-transform_idle="o:1;"
                data-transform_in="z:0;rX:0;rY:0;rZ:0;sX:0.8;sY:0.8;skX:0;skY:0;opacity:0;s:1010;e:Power4.easeOut;"
                data-transform_out="opacity:0;s:300;"
                data-width="['none','none','none','none']"
                data-x="1015"
                data-y="383"
                style="z-index: 14"
            >
              <img
                  alt=""
                  data-hh="auto"
                  data-no-retina
                  data-ww="auto"
                  src="/assets/revslider/assets/ca039-06.png"
              />
            </div>
            <!-- LAYER NR. 11 -->
            <div
                id="slide-3-layer-21"
                class="tp-caption tp-resizeme"
                data-height="['none','none','none','none']"
                data-responsive_offset="on"
                data-start="1920"
                data-transform_idle="o:1;"
                data-transform_in="z:0;rX:0;rY:0;rZ:0;sX:0.8;sY:0.8;skX:0;skY:0;opacity:0;s:1000;e:Power4.easeOut;"
                data-transform_out="opacity:0;s:300;"
                data-width="['none','none','none','none']"
                data-x="758"
                data-y="505"
                style="z-index: 15"
            >
              <img
                  alt=""
                  data-hh="auto"
                  data-no-retina
                  data-ww="auto"
                  src="/assets/revslider/assets/cbb91-07.png"
              />
            </div>
            <!-- LAYER NR. 12 -->
            <div
                id="slide-3-layer-24"
                class="tp-caption tp-resizeme"
                data-height="['none','none','none','none']"
                data-responsive_offset="on"
                data-start="2480"
                data-transform_idle="o:1;"
                data-transform_in="x:-50px;opacity:0;s:1000;e:Power2.easeInOut;"
                data-transform_out="opacity:0;s:300;"
                data-width="['none','none','none','none']"
                data-x="692"
                data-y="444"
                style="z-index: 16"
            >
              <img
                  alt=""
                  data-hh="auto"
                  data-no-retina
                  data-ww="auto"
                  src="/assets/revslider/assets/34673-14.png"
              />
            </div>
            <!-- LAYER NR. 13 -->
            <div
                id="slide-3-layer-25"
                class="tp-caption tp-resizeme"
                data-height="['none','none','none','none']"
                data-responsive_offset="on"
                data-start="2690"
                data-transform_idle="o:1;"
                data-transform_in="y:-50px;opacity:0;s:1000;e:Power2.easeInOut;"
                data-transform_out="opacity:0;s:300;"
                data-width="['none','none','none','none']"
                data-x="914"
                data-y="302"
                style="z-index: 17"
            >
              <img
                  alt=""
                  data-hh="auto"
                  data-no-retina
                  data-ww="auto"
                  src="/assets/revslider/assets/63023-12.png"
              />
            </div>
            <!-- LAYER NR. 14 -->
            <div
                id="slide-3-layer-22"
                class="tp-caption tp-resizeme"
                data-height="['none','none','none','none']"
                data-responsive_offset="on"
                data-start="2930"
                data-transform_idle="o:1;"
                data-transform_in="y:50px;opacity:0;s:1000;e:Power3.easeInOut;"
                data-transform_out="opacity:0;s:300;"
                data-width="['none','none','none','none']"
                data-x="809"
                data-y="595"
                style="z-index: 18"
            >
              <img
                  alt=""
                  data-hh="172px"
                  data-no-retina
                  data-ww="436px"
                  src="/assets/revslider/assets/41966-15.png"
              />
            </div>
            <!-- LAYER NR. 15 -->
            <div
                id="slide-3-layer-23"
                class="tp-caption tp-resizeme"
                data-height="['none','none','none','none']"
                data-responsive_offset="on"
                data-start="2220"
                data-transform_idle="o:1;"
                data-transform_in="x:50px;opacity:0;s:1000;e:Power2.easeInOut;"
                data-transform_out="opacity:0;s:300;"
                data-width="['none','none','none','none']"
                data-x="1097"
                data-y="442"
                style="z-index: 19"
            >
              <img
                  alt=""
                  data-hh="auto"
                  data-no-retina
                  data-ww="auto"
                  src="/assets/revslider/assets/b7030-13.png"
              />
            </div>
          </li>
        </ul>
        <div
            class="tp-bannertimer tp-bottom"
            style="visibility: hidden !important"
        ></div>
      </div>
    </div>
  </div>
  <!-- Banner END -->
  <!-- Main Content -->
  <div class="main-content">
    <!-- Works -->
    <section class="iq-works main-bg position-relative">
      <div class="container">
        <div class="row">
          <div class="col-sm-12 text-center">
            <div class="section-title">
              <p class="mt-2 text-uppercase text-white iq-fw-3 iq-ls-3">
                Comment fonctionne la SMARTCARD
              </p>
              <h2 class="title text-white iq-fw-8">3 Etapes simples</h2>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-4 text-center">
            <div class="iq-workinfo">
              <div class="service-shap mb-4 iq-rmt-40">
                <img alt="" class="img-fluid" src="/assets/images/work/01.png"/>
              </div>
              <h3 class="title text-white">1. Inscription</h3>
              <span class="mb-0 text-uppercase text-white iq-fw-3 iq-ls-1"
              >Créez votre compte gratuitement et accédez à nos différents
                services.</span
              >
            </div>
          </div>
          <div class="col-lg-4 text-center">
            <div class="iq-workinfo">
              <div class="service-shap mb-4 iq-rmt-40">
                <img alt="" class="img-fluid" src="/assets/images/work/02.png"/>
              </div>
              <h3 class="title text-white">2. Confection</h3>
              <span class="mb-0 text-uppercase text-white iq-fw-3 iq-ls-1"
              >Décidez vous-même du design de votre SMARTCARD et de votre
                forfait.</span
              >
            </div>
          </div>
          <div class="col-lg-4 text-center">
            <div class="iq-workinfo">
              <div class="service-shap mb-4 iq-rmt-40">
                <img alt="" class="img-fluid" src="/assets/images/work/03.png"/>
              </div>
              <h3 class="title text-white">3. Livraison</h3>
              <span class="mb-0 text-uppercase text-white iq-fw-3 iq-ls-1"
              >Votre SMARTCARD disponible 24h/24 et vous avez votre carte
                physique.</span
              >
            </div>
          </div>
        </div>
      </div>
      <div class="iq-way">
        <img alt="image" class="img-fluid" src="/assets/images/work/2.png"/>
      </div>
    </section>
    <!-- Works END -->
    <div class="iq-video overview-block-pb position-relative">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-9">
            <div class="position-relative">
              <img alt="" class="img-fluid w-100" src="/assets/images/video/2.png"/>
              <div class="video-people">
                <div class="scrollme">
                  <img
                      alt="image"
                      class="img-fluid one animateme"
                      data-from="1"
                      data-to="0"
                      data-translatex="-300"
                      data-when="span"
                      src="/assets/images/video/1.png"
                  />
                </div>
                <div class="scrollme">
                  <img
                      alt="image"
                      class="img-fluid two animateme"
                      data-from="1"
                      data-to="0"
                      data-translatex="300"
                      data-when="span"
                      src="/assets/images/video/3.png"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Choose From  -->
    <section class="iq-choose-info"></section>
    <!-- Choose From END -->
    <!-- Choose From  -->
    <div class="main-content">
      <!--Solutions From  -->
      <section class="iq-solutions position-relative">
        <div class="container">
          <div class="row no-gutters flex-row-reverse">
            <div class="col-lg-6">
              <img alt="" class="img-fluid" src="/assets/images/others/1.png"/>
            </div>
            <div class="col-lg-6 align-self-center">
              <h2 class="iq-fw-8 mb-2 iq-mt-40">
                SMARTCARD, la meilleure carte professionnelle.
              </h2>
              <p class="mr-3 text-justify">
                Votre carte de visite numérique entièrement personnalisable avec
                de multiples fonctionnalités. Incluant un puissant panneau
                d'administration qui vous permet de gérer l'activité de votre
                carte.
              </p>
              <ul class="listing-mark">
                <li>
                  Personnalisez votre carte pour qu'elle corresponde à votre
                  marque et à vos besoins.
                </li>
                <li>
                  Partagez vos informations à toute personne et en tout lieu.
                </li>
                <li>Des forfaits flexibles et accessibles à tous.</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <!-- Solutions END -->
      <!-- Choose From  -->
      <section class="iq-choose-info position-relative iq-rmt-40">
        <div class="container">
          <div class="row">
            <div class="col-lg-6 align-self-center">
              <img
                  alt=""
                  class="img-fluid wow fadeInLeft"
                  src="/assets/images/others/31.png"
              />
            </div>
            <div class="col-lg-6 wow fadeInRight">
              <h2 class="iq-fw-8 mb-2 iq-mt-40">Pourquoi SMARTCARD ?</h2>
              <p class="iq-font-18 mb-3">
                SMARTCARD est le choix judicieux pour votre carte de visite digitale :
              </p>
              <ul class="iq-project-info">
                <li>
                  <div class="media service-info mt-0 mb-0">
                    <div class="iq-feature-shap">
                      <i class="ion-ios-cloud-download-outline pt-2"></i>
                    </div>
                    <div class="media-body ml-3">
                      <h5 class="mt-0 mb-2 iq-fw-8">
                        <a href="service-detail.html">Accessibilité</a>
                      </h5>
                      <p class="mb-0">
                        Vous pouvez facilement partager vos informations de contact avec d'autres personnes, même à distance.
                      </p>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="media service-info mt-0 mb-0">
                    <div class="iq-feature-shap">
                      <i class="ion-ios-pie-outline pt-2"></i>
                    </div>
                    <div class="media-body ml-3">
                      <h5 class="mt-0 mb-2 iq-fw-8">
                        <a href="service-detail.html">Personnalisation</a>
                      </h5>
                      <p class="mb-0">
                        Vous pouvez personnaliser votre carte de visite digitale selon vos envies et la modifier à tout moment. 
                        Cela vous permettra de vous démarquer et de vous présenter de manière unique.
                      </p>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="media service-info mt-0 mb-0">
                    <div class="iq-feature-shap">
                      <i class="ion-ios-world-outline pt-2"></i>
                    </div>
                    <div class="media-body ml-3">
                      <h5 class="mt-0 mb23 iq-fw-8">
                        <a href="service-detail.html">Suivi</a>
                      </h5>
                      <p class="mb-0">
                        Vous pouvez suivre les statistiques d'utilisation de votre carte de visite digitale, telles que le nombre de vues et de partages. 
                        Cela peut aider à mesurer l'efficacité de votre réseau professionnel et à améliorer votre stratégie de marketing personnel.
                      </p>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="scrollme">
          <img
              alt="image"
              class="img-fluid iqwork-right animateme"
              data-from="0.5"
              data-to="0"
              data-translatey="100"
              data-when="exit"
              src="/assets/images/others/32.png"
          />
        </div>
      </section>
      <!-- Choose From End -->

      <!-- Service Info  -->
      <section class="iq-project-info position-relative iq-rmt-40 pt-0">
        <div class="container">
          <div class="row">
            <div class="col-sm-12 text-center">
              <div class="section-title">
                <p class="text-uppercase iq-fw-3 iq-ls-3">Fonctionnalités</p>
                <h2 class="title iq-fw-8">SMARTCARD c'est :</h2>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12 col-md-6 col-lg-4">
              <div class="media iq-project-info">
                <div class="media-body mr-3 text-right float-left">
                  <h5 class="mt-0 mb-1 iq-fw-8">
                    <a href="service-detail.html">QR CODE spécial</a>
                  </h5>
                  <p class="mb-0">Qui donne accès à vos informations à jour.</p>
                </div>
                <div class="service-shap align-self-center float-right">
                  <i class="fa fa-qrcode"></i>
                </div>
              </div>
              <div class="media iq-project-info">
                <div class="media-body mr-3 text-right float-left">
                  <h5 class="mt-0 mb-1 iq-fw-8">
                    <a href="service-detail.html">Espace de gestion</a>
                  </h5>
                  <p class="mb-0">
                    Des statistiques et des options d'administration.
                  </p>
                </div>
                <div class="service-shap align-self-center float-right">
                  <i class="ion-ios-pie-outline pt-2"></i>
                </div>
              </div>
            </div>
            <div class="col-md-4 align-self-center feature-images">
              <img alt="image" class="img-fluid" src="/assets/images/others/a.png"/>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-4">
              <div class="media iq-project-info">
                <div class="service-shap align-self-center">
                  <i class="ion-ios-gear-outline pt-2"></i>
                </div>
                <div class="media-body ml-3">
                  <h5 class="mt-0 mb-1 iq-fw-8">
                    <a href="service-detail.html">Enregistrer contact</a>
                  </h5>
                  <p class="mb-0">Sauvegarder dans le repertoire en 1 clic.</p>
                </div>
              </div>
              <div class="media iq-project-info">
                <div class="service-shap align-self-center">
                  <i class="ion-ios-briefcase-outline pt-2"></i>
                </div>
                <div class="media-body ml-3">
                  <h5 class="mt-0 mb-1 iq-fw-8">
                    <a href="service-detail.html">Réseaux sociaux</a>
                  </h5>
                  <p class="mb-0">
                    Intégrez vos liens sociaux sur votre SMARTCARD.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="scrollme">
          <img
              alt="image"
              class="img-fluid service-right animateme"
              data-from="0.5"
              data-to="0"
              data-translatey="100"
              data-when="exit"
              src="/assets/images/others/32.png"
          />
        </div>
      </section>
    </div>

  </div>
  <!-- Main Content END -->


  <!-- Modal Ma carte -->
  <div
      id="carteModal"
      aria-hidden="true"
      aria-labelledby="carteModalLabel"
      class="modal fade"
      tabindex="-1"
  >
    <div class="modal-dialog modal-lg modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">MA SMARTCARD</h5>
          <button
              aria-label="Close"
              class="close"
              data-dismiss="modal"
              type="button"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body row">
          <div class="col-lg-6 col-sm-12 col-xs-12">
            <a href="/create-card">
              <div class="main-blog">
                <div class="blog-img">
                  <img
                      alt="image"
                      class="img-fluid"
                      src="/assets/images/carte/commander.png"
                  />
                </div>
                <div class="blog-detail text-center">
                  <a href="/create-card">
                    <h5 class="mt-1 iq-fw-8">COMMANDER UNE CARTE</h5>
                  </a>
                </div>
              </div>
            </a>
          </div>
          <div class="col-lg-6 col-sm-12 col-xs-12">
            <a href="/login">
              <div class="main-blog">
                <div class="blog-img">
                  <img
                      alt="image"
                      class="img-fluid"
                      src="/assets/images/carte/acceder.png"
                  />
                </div>
                <div class="blog-detail text-center">
                  <a href="#">
                    <h5 class="mt-1 iq-fw-8 text-uppercase">
                      Accéder à mon espace
                    </h5>
                  </a>
                </div>
              </div>
            </a>
          </div>
        </div>
        <div class="modal-footer">
          <button class="btn btn-secondary" data-dismiss="modal" type="button">
            Fermer
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "AccueilView.vue",
}

</script>

<style scoped>

</style>
