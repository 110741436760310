<template>
  <section class="iq-breadcrumb">

    <div class="iq-breadcrumb-info">
      <div class="container">
        <div class="row">
          <div class="col-sm-12  col-md-7 align-self-center">
            <h2 class="iq-fw-8 mb-3">Blog</h2>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="index.html">Accueil</a></li>
                <li class="breadcrumb-item active" aria-current="page">Vos avis compte pour nous</li>
              </ol>
            </nav>
          </div>
          <div class="col-md-5">
            <img src="/assets/images/breadcrumb/blog.png" class="img-fluid" alt="">
          </div>
        </div>
      </div>
    </div>
    <div class="iq-breadcrumb-img">
      <img src="/assets/images/breadcrumb/02.png" class="img-fluid breadcrumb-two" alt="image">
    </div>
  </section>

  <div class="main-content">
    <section class="iq-blogs">
      <div class="container">
        <div class="row">

          <div class="col-lg-4">
            <div class="left-side-blog">
              <div class="iq-sidebar-widget">
                <div class="iq-widget-search position-relative"><a href="javascript:void(0)"> <i class="fa fa-search"></i></a>
                  <input type="search" placeholder="Recherche" class="form-control placeholder search-btn">
                </div>
              </div>
              <div class="blog-title-img text-center iq-mt-80">
                <img src="/assets/images/blog/blog-detail/05.jpg" class="img-fluid rounded-circle mb-3" alt="images">
                <h5>A propos de l'auteur</h5>
                <p class="mb-0">Progravida nibh vel velit auctor alinean sollicitudin, lorem quis bibendum auctor, nisi elit consequat.</p>
              </div>
              <div class="iq-mt-80">
                <h5 class="iq-widget-title iq-fw-8 mb-4">Articles récents</h5>
                <div class="media mb-3">
                  <img class="mr-3" src="/assets/images/blog/blog-detail/01.jpg" alt="image">
                  <div class="media-body">
                    <a href="javascript:void(0)">
                      <h6 class="mt-0 iq-fw-8">Une recherche entièrement dédiée.</h6>
                    </a>
                    February 2019
                  </div>
                </div>
              </div>
              <div class="iq-sidebar-widget iq-mt-80">
                <h5 class="iq-fw-8 mb-4">Catégories</h5>
                <ul class="d-block">
                  <li class="d-block mb-3"><a href="javascript:void(0)" class="iq-fw-5">Digtel Agency<span>8</span></a></li>
                </ul>
              </div>

            </div>
          </div>

          <div class="col-lg-8 iq-rmt-40">
            <div class="blog-img">
              <img src="/assets/images/blog/01.jpg" class="img-fluid mb-4" alt="image">
              <ul class="d-inline-block">
                <li class="d-inline-block"><a href="javascript:void(0)" class="mr-3"><i class="far fa-calendar-alt"></i> 01 January 2019</a></li>
                <li class="d-inline-block">|<a href="javascript:void(0)"><span class="ml-3">Creative</span> </a></li>
              </ul>
              <h3 class="title iq-fw-8 mt-3">Un constat entièrement dédié</h3>
              <p class="mb-3">Progravida nibh vel velit auctor alinean sollicitudin, lorem quis bibendum auctor, nisi elit consequat ipsum,Lorem Ipsum.lorem quis bibendum auctor. Progravida nibh vel velit auctor alinean sollicitudin, lorem quis bibendum auctor, nisi elit consequat ipsum,Lorem Ipsum.lorem quis bibendum auctor. Progravida nibh vel velit auctor alinean sollicitudin, lorem quis bibendum auctor, nisi elit consequat ipsum,Lorem Ipsum.lorem quis bibendum auctor. Progravida nibh vel velit auctor alinean sollicitudin, lorem quis bibendum auctor, nisi elit consequat ipsum,Lorem Ipsum.lorem quis bibendum auctor. Progravida nibh vel velit auctor alinean sollicitudin, lorem quis bibendum auctor, nisi elit consequat ipsum,Lorem Ipsum.lorem quis bibendum auctor.</p>
              <p class="mb-4">Progravida nibh vel velit auctor alinean sollicitudin, lorem quis bibendum auctor, nisi elit consequat ipsum,Lorem Ipsum.lorem quis bibendum auctor.</p>
              <div class="iq-blockquote position-relative ">
                <blockquote class="blockquote green-bg double-quotes">
                  <h5 class="mb-0 text-white">Nibh vel velit auctor alinean sollicitudin, lorem quis
                    bibendum auctor, nisi elit consequat ipsum
                  </h5>
                </blockquote>
              </div>
              <div class="blog-finding">
                <p>Progravida nibh vel velit auctor alinean sollicitudin, lorem quis bibendum auctor, nisi elit consequat ipsum,Lorem Ipsum.lorem quis bibendum auctor.</p>
                <ul class="d-inline-block float-right">
                  <li class="d-inline-block mr-3"><a href="javascript:void(0)"><i class="far fa-thumbs-up"></i> <span class="iq-fw-6">90</span></a></li>
                  <li class="d-inline-block"><a href="javascript:void(0)"><i class="far fa-comment-alt"></i> <span class="iq-fw-6">120</span></a></li>
                </ul>
              </div>
            </div>
            <div class="clearfix"></div>

          </div>
        </div>
      </div>
    </section>
  </div>

</template>

<script>
export default {
  name: "BlogDetailsView.vue"
}
</script>

<style scoped>

</style>
