<template>
  <section class="iq-breadcrumb">
    <div class="iq-breadcrumb-info">
      <div class="container">
        <div class="row">
          <div class="col-sm-12 col-md-7 align-self-center">
            <h2 class="iq-fw-8 mb-3">Contact</h2>
          </div>
        </div>
      </div>
    </div>
    <div class="iq-breadcrumb-img">
      <img
        src="/assets/images/breadcrumb/02.png"
        class="img-fluid breadcrumb-two"
        alt="image"
      />
    </div>
  </section>
  <!-- Main Content -->
  <div class="main-content">
    <section class="iq-contact-us pb-0">
      <div class="container">
        <div class="row">
          <div class="col-sm-12">
            <div class="section-title mb-4">
              <h2 class="title iq-fw-8">Laissez-nous un message</h2>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <div class="project-form mb-3">
              <div class="row">
                <div class="col-lg-6">
                  <div class="form-group">
                    <input
                      type="text"
                      class="form-control contact-first-name"
                      required
                      v-model="nomComplet"
                      placeholder="Nom Complet"
                    />
                  </div>

                  <div class="form-group">
                    <input
                      type="text"
                      class="form-control contact-phone"
                      required
                      v-model="telephone"
                      placeholder="Téléphone"
                    />
                  </div>
                  <div class="form-group">
                    <input
                      type="email"
                      class="form-control contact-email"
                      required
                      v-model="email"
                      placeholder="Email"
                    />
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="form-group">
                    <textarea
                      class="form-control contact-message"
                      required
                      v-model="message"
                      placeholder="Votre message ici"
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-12">
            <button class="slide-button button contact-us-button" type="submit">
              <div class="first">Envoyer</div>
              <div class="second" @click="onSendData()">Envoyer</div>
            </button>
          </div>
        </div>
      </div>
    </section>
    <section>
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="row">
              <div class="col-lg-6">
                <h2 class="title iq-fw-8">Nos contacts</h2>

                <div class="iq-address mt-3">
                  <div class="media">
                    <div class="mr-3 contact-icon float-left">
                      <i class="fa fa-map-marker" aria-hidden="true"></i>
                    </div>
                    <div class="media-body">
                      <p>
                        Angré 8<sup>e</sup> tranche <br /><span class="iq-fw-5"
                          >Immeuble Youma</span
                        >
                      </p>
                    </div>
                  </div>
                  <div class="media">
                    <div class="mr-3 contact-icon float-left">
                      <i class="fas fa-envelope"></i>
                    </div>
                    <div class="media-body">
                      <p class="iq-fw-5">smartcard@sm-ic.com</p>
                    </div>
                  </div>
                  <div class="media">
                    <div class="mr-3 contact-icon float-left">
                      <i class="fa fa-phone"></i>
                    </div>

                    <div class="media-body">
                      <p class="iq-fw-5">(+225) 27 22 51 75 18</p>
                    </div>
                  </div>
                  <div class="social-media mt-4">
                    <ul class="social">
                      <li>Suivez nous sur :</li>
                      <li>
                        <a href="#"><i class="fab fa-facebook-f"></i></a>
                      </li>
                      <li>
                        <a href="#"><i class="fab fa-linkedin"></i></a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="col-lg-6">
                <iframe
                  class="w-100 contact-ifream"
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d789.9066548911883!2d-3.9801340820954305!3d5.3929774489078754!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xfc193c4aa284035%3A0x7accaf8f40aa1b55!2sSMART%20INNOVATION%20CENTER!5e0!3m2!1sen!2sci!4v1676462683352!5m2!1sen!2sci"
                  allowfullscreen
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";

const ERRORS = {
  EMPTY_FIELD: "Le champ {fieldName} est obligatoire",
  INVALID_FIELD: "Le champ {fieldName} est invalide",
  NON_NUMERIC_FIELD:
    "Le champ {fieldName} doit contenir que des caractères numériques",
  NON_ALPHABETIC_FIELD:
    "Le champ {fieldName} doit contenir que des caractères alphabétiques",
  INVALID_EMAIL: "Veuillez saisir une adresse email valide",
  INVALID_FORM: "Veuillez remplir tous les champs",
};

export default {
  name: "ContactView.vue",

  data: function () {
    return {
      formData: {
        nomComplet: "",
        telephone: "",
        email: "",
        message: "",
      },
    };
  },

  methods: {
    onSendData() {
      this.formData.nomComplet = this.nomComplet;
      this.formData.telephone = this.telephone;
      this.formData.email = this.email;
      this.formData.message = this.message;

      console.log(this.formData);

      const result = this.verificationEntry(
        this.formData.nomComplet,
        this.formData.telephone,
        this.formData.email,
        this.formData.message
      );
      console.log(result);
      if (result) {
        console.log(this.formData);
        axios
          .post("https://www.smartcard225.com:7001/api/v1/contacts", this.formData)
          .then((response) => {
            Swal.fire({
              title: "Succès",
              text: "Message envoyé avec succès ",
              icon: "success",
            }).then((result) => {
              if (result.isConfirmed) {
                location.reload(true);
              }
            });
          })
          .catch((error) => {
            Swal.fire({
              title: "Erreur",
              text: error.toString(),
              icon: "error",
            });
          });
      } else {
        Swal.fire({
          title: "Erreur",
          text: result.message,
          icon: "error",
        });
      }
    },

    verificationEntry(VnomComplet, Vtelephone, Vemail, Vmessage) {
      if (this.isUndefined(VnomComplet)) {
        return this.showErrorSwal(
          ERRORS.INVALID_FIELD.replace("{fieldName}", "Nom Complet")
        );
      } else {
        if (this.isNullOrEmpty(VnomComplet)) {
          return this.showErrorSwal(
            ERRORS.EMPTY_FIELD.replace("{fieldName}", "Nom Complet")
          );
        } else {
          if (this.verifyRegexAlphabetAZ(VnomComplet)) {
            if (this.isUndefined(Vtelephone)) {
              return this.showErrorSwal(
                ERRORS.INVALID_FIELD.replace("{fieldName}", "Téléphone")
              );
            } else {
              if (this.isNullOrEmpty(Vtelephone)) {
                return this.showErrorSwal(
                  ERRORS.EMPTY_FIELD.replace("{fieldName}", "Téléphone")
                );
              } else {
                if (this.verifyRegexNumber(Vtelephone)) {
                  if (this.isUndefined(Vemail)) {
                    return this.showErrorSwal(
                      ERRORS.INVALID_FIELD.replace("{fieldName}", "Email")
                    );
                  } else {
                    if (this.isNullOrEmpty(Vemail)) {
                      return this.showErrorSwal(
                        ERRORS.EMPTY_FIELD.replace("{fieldName}", "Email")
                      );
                    } else {
                      if (this.verifyRegexEmail(Vemail)) {
                        if (this.isUndefined(Vmessage)) {
                          return this.showErrorSwal(
                            ERRORS.INVALID_FIELD.replace(
                              "{fieldName}",
                              "Message"
                            )
                          );
                        } else {
                          if (this.isNullOrEmpty(Vemail)) {
                            return this.showErrorSwal(
                              ERRORS.EMPTY_FIELD.replace(
                                "{fieldName}",
                                "Message"
                              )
                            );
                          } else {
                            return true;
                          }
                        }
                      } else {
                        return this.showErrorSwal(ERRORS.INVALID_EMAIL);
                      }
                    }
                  }
                } else {
                  return this.showErrorSwal(
                    ERRORS.NON_NUMERIC_FIELD.replace("{fieldName}", "Téléphone")
                  );
                }
              }
            }
          } else {
            return this.showErrorSwal(
              ERRORS.NON_ALPHABETIC_FIELD.replace("{fieldName}", "Nom Complet")
            );
          }
        }
      }
    },

    verifyRegexNumber(Vtelephone) {
      const regex = /^[0-9]+$/;
      const input = Vtelephone;
      if (regex.test(input)) {
        return true;
      } else {
        return false;
      }
    },
    verifyRegexAlphabetAZ(VnomComplet) {
      const regex = /^[a-zA-Z\s]+$/;
      const input = VnomComplet;
      if (regex.test(input)) {
        return true;
      } else {
        return false;
      }
    },
    verifyRegexEmail(Vemail) {
      const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const email = Vemail;
      if (regex.test(email)) {
        return true;
      } else {
        return false;
      }
    },
    isNullOrEmpty(str) {
      return str === null || str.trim() === "";
    },
    isUndefined(str) {
      return typeof str === "undefined";
    },
    showErrorSwal(message) {
      Swal.fire({
        title: "Erreur",
        text: message,
        icon: "error",
      });
    },
  },
};
</script>

<style scoped></style>
