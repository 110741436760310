<template>
  <header id="main-header" class="header-one">
   <NavWiew/>
  </header>
</template>

<script>
import NavWiew from './NavWiew.vue';
export default {
  name: "HeaderView.vue",
  components: {NavWiew}
}
</script>

<style scoped>

</style>
