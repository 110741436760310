<template>
  <section class="iq-contact-us pb-0">
    <form @submit.prevent="handleSubmit">
      <div class="container pt-3">
        <div class="row">
          <div class="col-md-6 col-xs-12">
            <h6 class="iq-fw-8 mb-3 text-center">
              <span
                class="h6 text-light"
                style="background-color: blue; padding: 5px; border-radius: 10%"
                >1</span
              >
              Informations Personnelles
            </h6>
            <hr />
            <div class="photo m-2 img-upload">
              <div class="team-img text-center" style="display: block">
                <label for="img_file_up" class="images">
                  <img
                    src="/assets/images/carte/avatar.png"
                    class="img_prv img-fluid rounded-circle hover_img1"
                    alt="image"
                  />
                  <img
                    src="/assets/images/carte/avataradd.png"
                    class="img_prv img-fluid rounded-circle hover_img2"
                    alt="image"
                    title="ajouter une photo"
                    style="opacity: 30%"
                  />
                </label>
                <input
                  name="photo"
                  id="img_file_up"
                  type="file"
                  accept=".jpg,.jpeg,.png"
                  style="display: none !important"
                  @change="photoSelected"
                />
              </div>
              <div class="text-center">
                <small class="text-danger" v-if="!isPhotoOk"
                  >Photo Obligatoire</small
                >
              </div>
            </div>
            <div class="form-group">
              <input
                type="text"
                class="form-control"
                required="required"
                name="nomComplet"
                placeholder="Nom Complet"
                v-model="nomComplet"
                pattern=".+"
                title="Votre nom complet est obligatoire"
              />
            </div>
            <div class="form-group">
              <input
                type="text"
                class="form-control"
                required="required"
                name="poste"
                placeholder="Poste / Profession"
                v-model="poste"
                pattern=".+"
                title="Votre poste est obligatoire"
              />
            </div>
            <div class="form-group">
              <input
                type="text"
                class="form-control"
                required="required"
                name="phoneOne"
                placeholder="Numéro de téléphone"
                v-model="phoneOne"
                title="Votre numéro de téléphone"
                minlength="10"
                maxlength="15"
                @input="isNumber($event)"
              />
            </div>
          </div>
          <div class="col-md-6 col-xs-12">
            <h6 class="iq-fw-8 mb-3 text-center">
              <span
                class="h6 text-light"
                style="background-color: blue; padding: 5px; border-radius: 10%"
                >2</span
              >
              Informations de l'Entreprise
            </h6>
            <hr />
            <div class="photo m-2 img-upload">
              <div class="team-img text-center" style="display: block">
                <label for="img_file_up2" class="images">
                  <img
                    src="/assets/images/carte/logo.png"
                    class="img_prv2 img-fluid rounded-circle hover_img1"
                    alt="image"
                  />
                  <img
                    src="/assets/images/carte/avataradd.png"
                    class="img_prv2 img-fluid rounded-circle hover_img2"
                    alt="image"
                    title="ajouter le logo"
                    style="opacity: 30%"
                  />
                </label>
                <input
                  name="logo"
                  id="img_file_up2"
                  type="file"
                  accept=".jpg,.jpeg,.png"
                  style="display: none !important"
                  @change="logoSelected"
                />
                <div class="text-center">
                  <small class="text-danger" v-if="!isLogoOk"
                    >Logo Obligatoire</small
                  >
                </div>
              </div>
            </div>
            <div class="form-group">
              <input
                type="text"
                class="form-control"
                required="required"
                name="denomination"
                placeholder="Nom de l'entreprise"
                v-model="denomination"
                pattern=".+"
                title="Le nom de l'entreprise est obligatoire"
              />
            </div>
            <div class="form-group">
              <input
                type="text"
                class="form-control"
                required="required"
                name="localisation"
                placeholder="Localisation"
                v-model="localisation"
                pattern=".+"
                title="La localisation est obligatoire"
              />
            </div>
            <div class="form-group">
              <input
                type="text"
                class="form-control"
                required="required"
                name="siteweb"
                placeholder="Site Web"
                v-model="siteweb"
                title="Veuillez entrer le site web"
              />
            </div>
          </div>
          <hr class="mb-5" />
          <div class="col-md-6 col-xs-12">
            <h6 class="iq-fw-8 mb-3 text-center">
              <span
                class="h6 text-light"
                style="background-color: blue; padding: 5px; border-radius: 10%"
                >3</span
              >
              Type de Carte
            </h6>
            <hr class="mb-4" />
            <div class="container mb-5 mt-2">
              <div class="row">
                <div
                  v-for="forfait in forfaits"
                  :key="forfait.id"
                  class="col-6"
                  style="cursor: pointer"
                >
                  <div
                    class="iq-pricing white-bg"
                    :id="'card' + forfait.type"
                    :class="{ active: activeCard === 'card' + forfait.type }"
                    @click="activeCard = 'card' + forfait.type"
                  >
                    <div class="pricing-header mb-3">
                      <h6 class="iq-fw-6 mb-2">{{ forfait.titre }}</h6>
                      <!--<h3 class="iq-fw-8">{{ forfait.prix }} F</h3>-->
                    </div>
                    <ul class="text-left">
                      <li v-for="option in forfait.optionForfaits" :key="option.id" class="mb-2">
                        <i class="fa fa-check-circle text-green mr-2"></i>{{ option.description }}
                      </li>
                    </ul>
                  </div>
                  <div class="text-center pt-2">
                    <input
                      type="radio"
                      :id="'forfait' + forfait.type"
                      :value="forfait.id"
                      name="forfaitID"
                      v-model="forfaitID"
                      @change="onForfaitChange($event)"
                      class="choixForfait"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-xs-12">
            <h6 class="iq-fw-8 mb-3 text-center">
              <span
                class="h6 text-light"
                style="background-color: blue; padding: 5px; border-radius: 10%"
                >4</span
              >
              Informations de Connexion
            </h6>
            <hr class="mb-4" />
            <div class="form-group">
              <input
                type="email"
                class="form-control"
                required="required"
                name="email"
                placeholder="Email"
                v-model="email"
                pattern=".+"
                title="Email obligatoire"
              />
            </div>
            <div class="form-group">
              <input
                type="password"
                class="form-control"
                required="required"
                name="password"
                placeholder="Mot de Passe"
                v-model="password"
                minlength="6"
                title="Mot de passe obligatoire"
              />
            </div>
            <div class="form-group">
              <input
                type="password"
                class="form-control"
                required="required"
                name="confpassword"
                placeholder="Confirmer mot de Passe"
                v-model="confpassword"
                minlength="6"
                title="Confirmation de mot de passe obligatoire"
              />
              <small v-if="!isPasswordValid" class="text-danger">
                Les mots de passe ne correspondent pas.
              </small>
            </div>
          </div>
          <div class="col-12 text-center">
            <button class="slide-button button" type="submit">
              <div class="first">Soumettre</div>
              <div class="second">Commander ma carte</div>
            </button>
          </div>
        </div>
      </div>
    </form>
  </section>
</template>

<script>
import $ from "@/assets/js/jquery-min";
import axios from "axios";
import Swal from 'sweetalert2';
export default {
  name: "CreateCarteView.vue",
  data() {
    return {
      forfaits: [],
      selectedPhoto: null,
      photo: "",
      nomComplet: "",
      poste: "",
      phoneOne: "",
      selectedLogo: null,
      logo: "",
      denomination: "",
      localisation: "",
      siteweb: "",
      forfaitID: null,
      activeCard: "",
      email: "",
      password: "",
      confpassword: "",
    };
  },
  computed: {
    isPasswordValid() {
      return this.confpassword === this.password;
    },
    isPhotoOk() {
      return this.selectedPhoto !== null;
    },
    isLogoOk() {
      return this.selectedLogo !== null;
    },
  },
  methods: {
    isNumber(event) {
      const pattern = /^[0-9+ ]*$/;
      if (!pattern.test(event.target.value)) {
        event.target.value = event.target.value.replace(/[^0-9+ ]/g, "");
        this.phoneOne = event.target.value;
      } else {
        this.phoneOne = event.target.value;
      }
    },
    onForfaitChange(event) {
      event.target.checked = true;
      if (event.target.id == "forfaitEnLigne") {
        this.activeCard = "cardEnLigne";
      }
      if (event.target.id == "forfaitHorsLigne") {
        this.activeCard = "cardHorsLigne";
      } // will log the id of the checked input
    },
    photoSelected(event) {
      this.selectedPhoto = event.target.files[0];
      this.photo = event.target.files[0].name;
    },
    logoSelected(event) {
      this.selectedLogo = event.target.files[0];
      this.logo = event.target.files[0].name;
    },
    handleSubmit() {
      if (!this.forfaitID) {
        alert("Selectionnez un type de carte");
      } else {
        if (!this.isPasswordValid) {
          alert("Les mots de passe ne correspondent pas");
        } else {
          if (!this.isPhotoOk || !this.isLogoOk) {
            alert("Veuillez importer les images nécessaires !");
          } else {

            const formData = new FormData();

            formData.append("photo", this.selectedPhoto, this.selectedPhoto.name);
            formData.append("nomComplet", this.nomComplet);
            formData.append("poste", this.poste);
            formData.append("phoneOne", this.phoneOne);
            formData.append("logo", this.selectedLogo, this.selectedLogo.name);
            formData.append("denomination", this.denomination);
            formData.append("localisation", this.localisation);
            formData.append("siteweb", this.siteweb);
            formData.append("forfaitID", this.forfaitID);
            formData.append("roleID", '649484ff096d7a427441fa31');
            formData.append("email", this.email);
            formData.append("password", this.password);

            axios.post('https://www.smartcard225.com:7001/api/v1/procedureCartes', formData)
            .then(response => {
              Swal.fire({
                title: 'Félicitations',
                text: 'Votre demande de carte a été effectuée !',
                icon: 'success',
                showClass: {
                  popup: 'animate__animated animate__fadeInDown'
                },
                hideClass: {
                  popup: 'animate__animated animate__fadeOutUp'
                },
                button: false, 
                timer: 3000
              });
              location.reload(true);
            })
            .catch(error => {
              console.log(error.message)
              Swal.fire({
                title: 'Erreur',
                text: 'Impossible de satisfaire votre requête !',
                icon: 'error',
                showClass: {
                  popup: 'animate__animated animate__fadeInDown'
                },
                hideClass: {
                  popup: 'animate__animated animate__fadeOutUp'
                },
                confirmButtonText: 'Compris'
              }).then((resultat)=>{
                if (resultat.isConfirmed) {
                  location.reload(true)
                }
              });
            });
          }
        }
      }
    },
  },
  mounted: function () {
    axios
      .get(`https://www.smartcard225.com:7001/api/v1/forfaits`)
      .then((response) => {
        console.log(response.data.forfaits);
        this.forfaits = response.data.forfaits;

        // Pour chaque forfait, obtenir la liste des optionForfaits correspondants
        for(let i = 0; i < this.forfaits.length; i++) {
            axios.get(`https://www.smartcard225.com:7001/api/v1/optionForfaits/getByForfaitID/${this.forfaits[i].id}`)
            .then((res) => {
                console.log(res.data.optionForfaits);
                this.forfaits[i].optionForfaits = res.data.optionForfaits;
            })
            .catch((err) => {
                console.error(err);
            });
        }
      })
      .catch(function (error) {
        //console.log(error);
      });
      
  },
};

//var currentTab = 0;

$(document).ready(function () {
  //
  $("#img_file_up").on("change", function (ev) {
    //Verifier image
    var filedata = this.files[0];
    var imgtype = filedata.type;
    var match = ["image/jpeg", "image/jpg", "image/png"];
    if (imgtype !== match[0] && imgtype !== match[1] && imgtype !== match[2]) {
      alert("Nous acceptons uniquement les images jpeg/jpg ou png");
    } else {
      if (this.files[0].size > 3145728) {
        alert("Photo trop lourde (> 3Mb)");
      } else {
        //Voir l'image
        var reader = new FileReader();
        reader.onload = function (ev) {
          $(".img_prv").attr("src", ev.target.result);
        };
        reader.readAsDataURL(this.files[0]);
      }
    }
  });

  $("#img_file_up2").on("change", function (ev) {
    //Verifier image
    var filedata = this.files[0];
    var imgtype = filedata.type;
    var match = ["image/jpeg", "image/jpg", "image/png"];
    if (imgtype !== match[0] && imgtype !== match[1] && imgtype !== match[2]) {
      alert("Nous acceptons uniquement les images jpeg/jpg ou png");
    } else {
      if (this.files[0].size > 3145728) {
        alert("Logo trop lourd (> 3Mb)");
      } else {
        //Voir l'image
        var reader = new FileReader();
        reader.onload = function (ev) {
          $(".img_prv2").attr("src", ev.target.result);
        };
        reader.readAsDataURL(this.files[0]);
      }
    }
  });

});
</script>

<style scoped></style>
