<template>
  <!-- loading -->
  <div id="loading">
    <div id="loading-center">
      <img src="/assets/images/loader.gif" alt="loder">
    </div>
  </div>
  <section class="iq-breadcrumb">
    <div class="iq-breadcrumb-info">
      <div class="container">
        <div class="row">
          <div class="col-sm-12 col-md-7 align-self-center">
            <h2 class="iq-fw-9 mb-3">FAQ</h2>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="#">Home</a></li>
                <li class="breadcrumb-item active" aria-current="page">FAQ</li>
              </ol>
            </nav>
          </div>
          <div class="col-md-5">
            <img src="/assets/images/breadcrumb/faq.png" class="img-fluid" alt="image">
          </div>
        </div>            </div>
    </div>
    <div class="iq-breadcrumb-img">
      <img src="/assets/images/breadcrumb/02.png" class="img-fluid breadcrumb-two" alt="image">
    </div>
  </section>
  <div class="main-content">
    <!-- Faq -->
    <section class="iq-asked position-relative">
      <div class="container">
        <div class="row">
          <div class="col-sm-12 text-center">
            <div class="section-title">
              <p class="mt-2 text-uppercase  iq-fw-3 iq-ls-3">Faq</p>
              <h2 class="title iq-fw-8">Frequently Asked Questions</h2>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <div class="iq-accordion">
              <div class="iq-ad-block"> <a href="javascript:void(0)" class="ad-title iq-fw-6 ">Why do we use it?</a>
                <div class="ad-details">

                  <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters.
                  </p>

                </div>
              </div>
              <div class="iq-ad-block"> <a href="javascript:void(0)" class="ad-title iq-fw-6 ">Where does it come from?</a>
                <div class="ad-details">

                  <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters.
                  </p>

                </div>
              </div>
              <div class="iq-ad-block "> <a href="javascript:void(0)" class="ad-title iq-fw-6 ">There are many variationof passages?</a>
                <div class="ad-details">
                  <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters.
                  </p>

                </div>
              </div>
              <div class="iq-ad-block "> <a href="javascript:void(0)" class="ad-title iq-fw-6 ">Standard chunk of Lorem Ipsum used since the 1500s?</a>
                <div class="ad-details">

                  <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters.
                  </p>

                </div>
              </div>
              <div class="iq-ad-block "> <a href="javascript:void(0)" class="ad-title iq-fw-6 ">This book is a treatise on the theory?</a>
                <div class="ad-details">

                  <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters.
                  </p>

                </div>
              </div>
              <div class="iq-ad-block "> <a href="javascript:void(0)" class="ad-title iq-fw-6 ">What is Lorem Ipsum?</a>
                <div class="ad-details">

                  <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters.
                  </p>

                </div>
              </div>
            </div>

          </div>

        </div>
      </div>

    </section>
    <!-- Faq END -->
  </div>
</template>

<script>
export default {
  name: "faqView.vue"
}
</script>

<style scoped>

</style>
