<template>
  <section class="iq-breadcrumb">

    <div class="iq-breadcrumb-info">
      <div class="container">
        <div class="row">
          <div class="col-sm-12 col-md-7 align-self-center">
            <h2 class="iq-fw-8 mb-3">About Us</h2>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="#">Home</a></li>
                <li class="breadcrumb-item active" aria-current="page">About Us</li>
              </ol>
            </nav>
          </div>
          <div class="col-md-5">
            <img src="/assets/images/breadcrumb/about.png" class="img-fluid" alt="">
          </div>
        </div>            </div>
    </div>
    <div class="iq-breadcrumb-img">
      <img src="/assets/images/breadcrumb/02.png" class="img-fluid breadcrumb-two" alt="image">
    </div>
  </section>
  <div class="main-content">
    <!-- Works -->
    <section class="iq-works position-relative">
      <div class="scrollme">

        <img src="/assets/images/service/1.png"  class="img-fluid service-one animateme"
             data-when="enter"
             data-from="0.5"
             data-to="0"
             data-translatey="300" alt="image">

      </div>
      <div class="container">
        <div class="row">
          <div class="col-sm-12 text-center">
            <div class="section-title">
              <p class="mb-0 text-uppercase iq-fw-5 iq-ls-2">Best Ever Services</p>
              <h2 class="title iq-fw-8">Best Services</h2>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6">
            <div class="services purple">
              <div class="services-info">
                <div class="service-shap purple"><i class="ion-ios-cloud-download-outline"></i></div>
                <h5 class="float-left mt-4">Data Analysis</h5>
                <div class="clearfix"></div>
                <p class="mt-3 mb-0">Progravida nibh vel velit auctor alinean sollicitudin, lorem quis bibendum auctor, nisi elit consequat.</p>
              </div>
              <a href="service.html" class="button-info">Read More <span class="float-right">. . .</span></a>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="services purple">
              <div class="services-info">
                <div class="service-shap purple"><i class="ion-ios-bookmarks-outline"></i></div>
                <h5 class="float-left mt-4">PPC Marketing</h5>
                <div class="clearfix"></div>
                <p class="mt-3 mb-0">Progravida nibh vel velit auctor alinean sollicitudin, lorem quis bibendum auctor, nisi elit consequat.</p>
              </div>
              <a href="service.html" class="button-info">Read More <span class="float-right">. . .</span></a>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="services purple mb-lg-0">
              <div class="services-info">
                <div class="service-shap purple"><i class="ion-ios-pie-outline"></i></div>
                <h5 class="float-left mt-4">Business Analytics</h5>
                <div class="clearfix"></div>
                <p class="mt-3 mb-0">Progravida nibh vel velit auctor alinean sollicitudin, lorem quis bibendum auctor, nisi elit consequat.</p>
              </div>
              <a href="service.html" class="button-info">Read More <span class="float-right">. . .</span></a>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="services purple mb-lg-0">
              <div class="services-info">
                <div class="service-shap purple"><i class="ion-ios-world-outline"></i></div>
                <h5 class="float-left mt-4">Social media marketing</h5>
                <div class="clearfix"></div>
                <p class="mt-3 mb-0">Progravida nibh vel velit auctor alinean sollicitudin, lorem quis bibendum auctor, nisi elit consequat.</p>
              </div>
              <a href="service.html" class="button-info">Read More <span class="float-right">. . .</span></a>
            </div>
          </div>
        </div>
      </div>
      <img src="/assets/images/service/2.png" class="img-fluid service-two" alt="image">
    </section>
    <!-- Works END -->
    <!-- Choose From  -->
    <section class="iq-solutions pt-0">
      <div class="container">
        <div class="row flex-row-reverse">
          <div class="col-lg-6 align-self-center wow fadeInRight">
            <h2 class="iq-fw-8 mb-2">Who We are</h2>
            <p class="mb-3">Progravida nibh vel velit auctor alinean sollicitudin, lorem quis bibendum auctor, nisi elit consequat ipsum,Lorem Ipsum.lorem quis bibendum auctor. Progravida nibh vel velit auctor alinean sollicitudin, lorem quis bibendum auctor, nisi elit consequat ipsum,Lorem Ipsum.lorem quis bibendum auctor.</p>
            <p class="mb-3">Progravida nibh vel velit auctor alinean sollicitudin, lorem quis bibendum auctor.</p>
          </div>
          <div class="col-lg-6">
            <img src="/assets/images/others/2.jpg" class="img-fluid wow fadeInLeft" alt="">
          </div>
        </div>
      </div>
    </section>
    <!-- Choose From END -->
    <!-- Team section -->
    <section class="iq-aboutteam  main-bg position-relative">
      <div class="container">
        <div class="row">
          <div class="col-sm-12 text-center">
            <div class="section-title">
              <p class="mt-5 text-uppercase text-white iq-fw-3 iq-ls-3">Meet the team</p>
              <h2 class="title  text-white iq-fw-8">Our Best Team</h2>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-3 col-md-6">
            <div class="team-box mb-4">
              <div class="team-img text-center">
                <img src="/assets/images/team/01.jpg" class="img-fluid rounded-circle" alt="image">
              </div>
              <div class="team-detail">
                <a class="team-plus" href="#"><i class="fas fa-plus"></i></a>
                <div class="team-info">
                  <h6 class="mb-0 text-white "><a href="team-detail.html">JD Scot</a></h6>
                  <span class="mb-0 text-white text-gray iq-fw-4">Web Designer</span>
                </div>
              </div>
              <div class="team-hover">
                <p>Progravida nibh vel velit auctor alinean sollicitudin nisi elit consequat ipsum.</p>
                <ul class="list-inline">
                  <li class="list-inline-item" ><a href="#"><i class="fab fa-facebook-f"></i></a></li>
                  <li class="list-inline-item" ><a href="#"><i class="fab fa-twitter"></i></a></li>
                  <li class="list-inline-item" ><a href="#"><i class="fab fa-linkedin-in"></i></a></li>
                  <li class="list-inline-item" ><a href="#"><i class="fab fa-instagram"></i></a></li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-6">
            <div class="team-box mb-4">
              <div class="team-img text-center">
                <img src="/assets/images/team/02.jpg" class="img-fluid rounded-circle" alt="image">
              </div>
              <div class="team-detail">
                <a class="team-plus" href="#"><i class="fas fa-plus"></i></a>
                <div class="team-info">
                  <h6 class="mb-0 text-white"><a href="team-detail.html">Kips Leo</a></h6>
                  <span class="mb-0 text-white text-gray iq-fw-4">Web Designer</span>
                </div>
              </div>
              <div class="team-hover">
                <p>Progravida nibh vel velit auctor alinean sollicitudin nisi elit consequat ipsum.</p>
                <ul class="list-inline">
                  <li class="list-inline-item" ><a href="#"><i class="fab fa-facebook-f"></i></a></li>
                  <li class="list-inline-item" ><a href="#"><i class="fab fa-twitter"></i></a></li>
                  <li class="list-inline-item" ><a href="#"><i class="fab fa-linkedin-in"></i></a></li>
                  <li class="list-inline-item" ><a href="#"><i class="fab fa-instagram"></i></a></li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-6">
            <div class="team-box mb-4">
              <div class="team-img text-center">
                <img src="/assets/images/team/03.jpg" class="img-fluid rounded-circle" alt="image">
              </div>
              <div class="team-detail">
                <a class="team-plus" href="#"><i class="fas fa-plus"></i></a>
                <div class="team-info">
                  <h6 class="mb-0 text-white"><a href="team-detail.html">Nik Jorden</a></h6>
                  <span class="mb-0 text-white text-gray iq-fw-4">Web Designer</span>
                </div>
              </div>
              <div class="team-hover">
                <p>Progravida nibh vel velit auctor alinean sollicitudin nisi elit consequat ipsum.</p>
                <ul class="list-inline">
                  <li class="list-inline-item" ><a href="#"><i class="fab fa-facebook-f"></i></a></li>
                  <li class="list-inline-item" ><a href="#"><i class="fab fa-twitter"></i></a></li>
                  <li class="list-inline-item" ><a href="#"><i class="fab fa-linkedin-in"></i></a></li>
                  <li class="list-inline-item" ><a href="#"><i class="fab fa-instagram"></i></a></li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-6">
            <div class="team-box mb-4">
              <div class="team-img text-center">
                <img src="/assets/images/team/04.jpg" class="img-fluid rounded-circle" alt="image">
              </div>
              <div class="team-detail">
                <a class="team-plus" href="#"><i class="fas fa-plus"></i></a>
                <div class="team-info">
                  <h6 class="mb-0 text-white"><a href="team-detail.html">Glen Jax</a></h6>
                  <span class="mb-0 text-white text-gray iq-fw-4">Zane Wills</span>
                </div>
              </div>
              <div class="team-hover">
                <p>Progravida nibh vel velit auctor alinean sollicitudin nisi elit consequat ipsum.</p>
                <ul class="list-inline">
                  <li class="list-inline-item" ><a href="#"><i class="fab fa-facebook-f"></i></a></li>
                  <li class="list-inline-item" ><a href="#"><i class="fab fa-twitter"></i></a></li>
                  <li class="list-inline-item" ><a href="#"><i class="fab fa-linkedin-in"></i></a></li>
                  <li class="list-inline-item" ><a href="#"><i class="fab fa-instagram"></i></a></li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-lg-12 text-center">
            <a class="slide-button button mb-0 mt-5" href="team.html">
              <div class="first">Explore All Team</div>
              <div class="second">Explore All Team</div>
            </a>
          </div>
        </div>
      </div>
    </section>
    <!-- Team END -->
    <!-- Our History -->
    <section class="iq-history">
      <div class="container">
        <div class="col-sm-12 text-center">
          <div class="section-title">
            <p class="mb-0 text-uppercase iq-fw-5 iq-ls-3">Our History</p>
            <h2 class="title iq-fw-8">Company History</h2>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="row">
          <div class="col-sm-12">
            <div class="cd-horizontal-timeline">
              <div class="timeline">
                <div class="events-wrapper">
                  <div class="events">
                    <ol>
                      <li><a href="#" data-date="16/01/2014" class="selected">16 Jan</a></li>
                      <li><a href="#" data-date="28/02/2014">28 Feb</a></li>
                      <li><a href="#" data-date="20/04/2014">20 Mar</a></li>
                      <li><a href="#" data-date="20/05/2014">20 May</a></li>
                      <li><a href="#" data-date="09/07/2014">09 Jul</a></li>
                      <li><a href="#" data-date="30/08/2014">30 Aug</a></li>
                      <li><a href="#" data-date="15/09/2014">15 Sep</a></li>
                      <li><a href="#" data-date="01/11/2014">01 Nov</a></li>
                      <li><a href="#" data-date="10/12/2014">10 Dec</a></li>
                      <li><a href="#" data-date="19/01/2015">29 Jan</a></li>
                      <li><a href="#" data-date="03/03/2015">3 Mar</a></li>
                    </ol>
                    <span class="filling-line" aria-hidden="true"></span>
                  </div>
                </div>

                <ul class="cd-timeline-navigation">
                  <li><a href="#" class="prev inactive">Prev</a></li>
                  <li><a href="#" class="next">Next</a></li>
                </ul>
              </div>
              <div class="events-content">
                <ol>
                  <li class=" selected " data-date="16/01/2014" >
                    <h3 class="text-white iq-fw-8 mb-3">Winners And Giving Prizes</h3>
                    <p class="text-white mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</p>
                  </li>
                  <li data-date="28/02/2014">
                    <h3 class="text-white iq-fw-8 mb-3">The standard Lorem Ipsum passage</h3>
                    <p class="text-white mb-0">Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore.</p>
                  </li>
                  <li data-date="20/04/2014">
                    <h3 class="text-white iq-fw-8 mb-3">Finibus Bonorum et Malorum</h3>
                    <p class="text-white mb-0">At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi.</p>
                  </li>
                  <li data-date="20/05/2014">
                    <h3 class="text-white iq-fw-8 mb-3">Donec lacinia posuere rhoncus</h3>
                    <p class="text-white mb-0">Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old.</p>
                  </li>
                  <li data-date="09/07/2014">
                    <h3 class="text-white iq-fw-8 mb-3">Neque porro quisquam dolorem ipsum</h3>
                    <p class="text-white mb-0">Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions.</p>
                  </li>
                  <li data-date="30/08/2014">
                    <h3 class="text-white iq-fw-8 mb-3">Lorem ipsum began as scrambled</h3>
                    <p class="text-white mb-0">Until recently, the prevailing view assumed lorem ipsum was born as a nonsense text. “It's not Latin, though it looks like it, and it actually says nothing,”</p>
                  </li>
                  <li data-date="15/09/2014">
                    <h3 class="text-white iq-fw-8 mb-3">Creation timelines for the standard </h3>
                    <p class="text-white mb-0">So how did the classical Latin become so incoherent? According to McClintock, a 15th century typesetter likely scrambled part of Cicero's De Finibus in order.</p>
                  </li>
                  <li data-date="01/11/2014">
                    <h3 class="text-white iq-fw-8 mb-3">Some claim lorem ipsum threatens</h3>
                    <p class="text-white mb-0">The strength of lorem ipsum is its weakness: it doesn't communicate. To some, designing a website around placeholder text is unacceptable, akin to sewing a custom.</p>
                  </li>
                  <li data-date="10/12/2014">
                    <h3 class="text-white iq-fw-8 mb-3">Coming full the internet's remixing </h3>
                    <p class="text-white mb-0">There was that time artists at Sequence opted to hand-Sharpie the lorem ipsum passage on a line of paper bags they designed for Chipotle—the result being a mixture of avant-garde.</p>
                  </li>
                  <li data-date="19/01/2015">
                    <h3 class="text-white iq-fw-8 mb-3">Below are the original Latin passages</h3>
                    <p class="text-white mb-0">Sed ut perspiciatis, unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam eaque ipsa, quae ab illo.</p>
                  </li>
                  <li data-date="03/03/2015">
                    <h3 class="text-white iq-fw-8 mb-3">Where does it come from?</h3>
                    <p class="text-white mb-0">Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making.</p>
                  </li>



                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Our History END -->
    <!-- Our Blog -->
    <section class="iq-blogs position-relative pt-0">
      <div class="container">
        <div class="col-sm-12 text-center">
          <div class="section-title">
            <p class="mb-0 text-uppercase iq-fw-5 iq-ls-2">Latest Articles</p>
            <h2 class="title iq-fw-8">Our Stories Post</h2>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="owl-carousel" data-loop="true" data-nav="false" data-dots="false" data-items="3" data-items-laptop="3" data-items-tab="2" data-items-mobile="1" data-items-mobile-sm="1">
              <div class="item">
                <div class="main-blog">
                  <div class="blog-img">
                    <img src="/assets/images/blog/01.jpg" class="img-fluid" alt="image">
                  </div>
                  <div class="blog-detail">
                    <a class="main-color iq-fw-8" href="all-blog-grid.html">Design Concept</a>
                    <a href="all-blog-grid.html">
                      <h5 class="mt-1 iq-fw-8">Fully dedicated finding.</h5>
                    </a>
                    <p class="mb-0">Progravida nibh vel velit auctor alinean sollicitudin.</p>
                    <div class="blog-info">
                      <a href=""><img src="/assets/images/blog/clients/01.jpg" class="img-fluid rounded-circle mr-3 user-img" alt="image"><span class="iq-fw-8 font-c iq-font-18">John Deo</span></a>
                      <ul class="d-inline-block float-right">
                        <li class="d-inline-block"><a href="javascript:void(0)"><i class="far fa-thumbs-up"></i> <span class="iq-fw-8 iq-font-14">90</span></a></li>
                        <li class="d-inline-block"><a href="javascript:void(0)"><i class="far fa-comment-alt"></i> <span class="iq-fw-8 iq-font-14">120</span></a></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div class="item">
                <div class="main-blog">
                  <div class="blog-img">
                    <img src="/assets/images/blog/02.jpg" class="img-fluid" alt="image">
                  </div>
                  <div class="blog-detail">
                    <a class="main-color iq-fw-8" href="all-blog-grid.html">Design Concept</a>
                    <a href="all-blog-grid.html">
                      <h5 class="mt-1 iq-fw-8">Progravida nibh vel.</h5>
                    </a>
                    <p class="mb-0">Progravida nibh vel velit auctor alinean sollicitudin.</p>
                    <div class="blog-info">
                      <a href=""><img src="/assets/images/blog/clients/02.jpg" class="img-fluid rounded-circle mr-3 user-img" alt="image"><span class="iq-fw-8 font-c iq-font-18">Glen Jax</span></a>
                      <ul class="d-inline-block float-right">
                        <li class="d-inline-block"><a href="javascript:void(0)"><i class="far fa-thumbs-up"></i> <span class="iq-fw-8 iq-font-14">90</span></a></li>
                        <li class="d-inline-block"><a href="javascript:void(0)"><i class="far fa-comment-alt"></i> <span class="iq-fw-8 iq-font-14">120</span></a></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div class="item">
                <div class="main-blog">
                  <div class="blog-img">
                    <img src="/assets/images/blog/03.jpg" class="img-fluid" alt="image">
                  </div>
                  <div class="blog-detail">
                    <a class="main-color iq-fw-8" href="all-blog-grid.html">Design Concept</a>
                    <a href="all-blog-grid.html">
                      <h5 class="mt-1 iq-fw-8">Auctor alinean.</h5>
                    </a>
                    <p class="mb-0">Progravida nibh vel velit auctor alinean sollicitudin.</p>
                    <div class="blog-info">
                      <a href=""><img src="/assets/images/blog/clients/03.jpg" class="img-fluid rounded-circle mr-3 user-img" alt="image"><span class="iq-fw-8 font-c iq-font-18">Kips Leo</span></a>
                      <ul class="d-inline-block float-right">
                        <li class="d-inline-block"><a href="javascript:void(0)"><i class="far fa-thumbs-up"></i> <span class="iq-fw-8 iq-font-14">90</span></a></li>
                        <li class="d-inline-block"><a href="javascript:void(0)"><i class="far fa-comment-alt"></i> <span class="iq-fw-8 iq-font-14">120</span></a></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div class="item">
                <div class="main-blog">
                  <div class="blog-img">
                    <img src="/assets/images/blog/04.jpg" class="img-fluid" alt="image">
                  </div>
                  <div class="blog-detail">
                    <a class="main-color iq-fw-8" href="#">Design Concept</a>
                    <h5 class="mt-1 iq-fw-8">Alinean sollicitudin.</h5>
                    <p class="mb-0">Progravida nibh vel velit auctor alinean sollicitudin.</p>
                    <div class="blog-info">
                      <a href=""><img src="/assets/images/blog/clients/04.jpg" class="img-fluid rounded-circle mr-3 user-img" alt="image"><span class="iq-fw-8 font-c iq-font-18">Glen Jax</span></a>
                      <ul class="d-inline-block float-right">
                        <li class="d-inline-block"><a href="#"><i class="far fa-thumbs-up"></i> <span class="iq-fw-8 iq-font-14">90</span></a></li>
                        <li class="d-inline-block"><a href="#"><i class="far fa-comment-alt"></i> <span class="iq-fw-8 iq-font-14">120</span></a></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="scrollme">
        <img src="/assets/images/blog/05.png" class="img-fluid blog-one animateme" data-when="exit"
             data-from="0.5"
             data-to="0"
             data-translatex="50"
             alt="#">
      </div>
    </section>
    <!-- Our Blog END -->

  </div>
</template>

<script>
export default {
  name: "AboutUseView.vue",
  components:{

  },



}
</script>

<style scoped>

</style>
