<template>
  <div class="login-from header-navbar reset-form light-gray-bg position-relative">
    <div class="row no-gutters">
      <div class="col-lg-6 align-items-stretch position-relative white-bg">
        <nav class="navbar navbar-expand-lg navbar-light">
          <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav ml-auto">
              <li class="nav-item">
                <a class="nav-link" href="index.html">Accueil</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="privacy-policy.html">Blog</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="javascript:void(0)">Contacts</a>
              </li>
            </ul>
          </div>
        </nav>
        <div class="login-info">
          <h2 class="iq-fw-8 mb-3">Réinitialiser mon mot de passe</h2>
          <h6>Veuillez entrer votre email afin de réinitialiser votre mot de passe.</h6>
          <form>
            <div class="form-group">
              <input type="email" class="form-control" placeholder="Votre Email" autocomplete="off" required>
            </div>
            <a class="slide-button button mr-3" href="#">
              <div class="first">Confirmer</div>
              <div class="second">Confirmer</div>
            </a>
          </form>
        </div>
        <ul class="social-list">
          <li><a href="#"><i class="fab fa-facebook-f"></i></a></li>
          <li><a href="#"><i class="fab fa-linkedin"></i></a></li>
          <li><a href="#"><i class="fab fa-whatsapp"></i></a></li>
        </ul>
      </div>
      <div class="col-lg-6 align-self-center position-relative">
        <div class="login-right-bar h-100 text-center">
          <img src="/assets/images/login/password-reset.png" class="img-fluid" alt="">
        </div>
      </div>
    </div>
    <img  src="/assets/images/login/2.png" class="img-fluid login-footer-one" alt="">
    <img  src="/assets/images/login/3.png" class="img-fluid login-footer-two" alt="">
    <!-- back-to-top -->
    <div id="back-to-top">
      <a class="top" id="top" href="#top"><i class="ion-ios-arrow-thin-up"></i></a>
    </div>
    <!-- back-to-top End -->
  </div>
</template>

<script>
export default {
  name: "PasswordResetView.vue"
}
</script>

<style scoped>

</style>
