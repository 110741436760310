<template>
  <footer class="footer-two">
    <img alt="image" class="img-fluid footer-one" src="/assets/images/footer/1.jpg"/>
    <div class="footer-top main-bg">
      <div class="container-fluid">
        <div class="row no-gutters">
          <div class="col-lg-3 col-md-4">
            <div class="iq-footer-logo">
              <a href="index.html">
                <img
                    alt="image"
                    class="img-fluid mb-4"
                    src="/assets/images/logo-white.png"/>
              </a>
            </div>
          </div>
          <div class="col-lg-6 col-md-4">
            <h5 class="text-white">
              Abonnez-vous pour être informé des mises à jours à venir.
            </h5>
            <form class="position-relative subscribe-form" @submit.prevent="handleSubmit">
              <div class="form-group mb-0">
                <input
                  class="form-control position-relative subscription-email"
                  placeholder="Votre Email ICI"
                  type="email"
                  required="required"
                  name="email"
                  v-model="email"
                  pattern=".+"
                  title="Email obligatoire"
                />
              </div>
              <button class="button bt-subscribe subscription-button" type="submit">
                <span class="fa-stack fa-lg">
                  <i class="fas fa-circle fa-stack-2x"></i>
                  <i class="fas fa-angle-right fa-stack-1x fa-inverse text-primary"></i>
                </span>
              </button>
            </form>
          </div>
          <div class="col-lg-3 col-md-4">
            <div class="social-media d-inline-block mb-4">
              <ul class="social">
                <li>
                  <a class="text-uppercase" href="#"
                  ><i class="fab fa-facebook-f iq-fw-6 pr-3"></i>facebook</a
                  >
                </li>
                <li>
                  <a class="text-uppercase" href="#"
                  ><i class="fab fa-linkedin iq-fw-6 pr-3"></i>linkedin</a
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <div class="footer-copyright text-center iq-fw-3">
              ©Copyright 2023 SMARTCARD.
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- back-to-top -->
    <div id="back-to-top">
      <a id="top" class="top" href="#top"
      ><i class="ion-ios-arrow-thin-up"></i
      ></a>
    </div>
    <!-- back-to-top End -->
  </footer>
</template>

<script>
import $ from "@/assets/js/jquery-min";
import axios from "axios";
import Swal from 'sweetalert2';
export default {
  name: "FooterView.vue",
  data() {
    return {
      email: ""
    };
  },
  methods: {
    handleSubmit() {
            const formData = new FormData();
            formData.append("email", this.email);

            axios.post('https://www.smartcard225.com:7001/api/v1/newsletters', formData)
            .then(response => {
              Swal.fire({
                title: 'Félicitations',
                text: 'Vous êtes enregistré dans notre liste de newsletter !',
                icon: 'success',
                showClass: {
                  popup: 'animate__animated animate__fadeInDown'
                },
                hideClass: {
                  popup: 'animate__animated animate__fadeOutUp'
                },
                button: false, 
                timer: 3000
              }).then(() => {
                  location.reload(true);
              })
            })
            .catch(error => {
              console.log(error.message)
              Swal.fire({
                title: 'Erreur',
                text: 'Impossible de satisfaire votre requête !',
                icon: 'error',
                showClass: {
                  popup: 'animate__animated animate__fadeInDown'
                },
                hideClass: {
                  popup: 'animate__animated animate__fadeOutUp'
                },
                confirmButtonText: 'Compris'
              }).then((resultat)=>{
                if (resultat.isConfirmed) {
                  location.reload(true)
                }
              });
            });
    },
  }
}
</script>

<style scoped>

</style>
