<template>

  <HeaderView v-if="!isLoginRoute"></HeaderView>

  <router-view/>

  <!--  Start Footer Section-->
  <FooterView></FooterView>
  <!--  End Footer Section-->

</template>

<style ></style>
<script>
import FooterView from './views/FooterView.vue';
import HeaderView from './views/HeaderView.vue';
import '@/assets/js/jquery-min.js';
export default {
  components: {HeaderView, FooterView},
  computed: {
    isLoginRoute() {
      return this.$route.path === '/login';
    }
  }
}
</script>
