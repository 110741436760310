<template>
  <!-- loading -->
  <div id="loading">
    <div id="loading-center">
      <img src="/assets/images/loader.gif" alt="loder">
    </div>
  </div>
  <!-- loading End -->
  <div class="iq-pagenotfound position-relative text-center">
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-12">
          <div class="pagenotfound-info ">
            <img  src="/assets/images/others/8.png" class="img-fluid mb-5 " alt="image">
            <h2 class="mb-3 iq-fw-8">Uh No! This Page Is Not Found</h2>
            <a class="slide-button button" href="index.html">
              <div class="first">Back to Home</div>
              <div class="second">Back to Home</div>
            </a>
          </div>
          <img  src="/assets/images/others/9.png" class="img-fluid pagenotfound-left" alt="image">
          <img  src="/assets/images/others/10.png" class="img-fluid pagenotfound-right" alt="image">
        </div>
      </div>
    </div>
    <!-- back-to-top -->
    <div id="back-to-top">
      <a class="top" id="top" href="#top"><i class="ion-ios-arrow-thin-up"></i></a>
    </div>
    <!-- back-to-top End -->
  </div>
</template>

<script>
export default {
  name: "404View.vue"
}
</script>

<style scoped>

</style>
