<template>
  <!-- Breadcrumb -->
  <section class="iq-breadcrumb">

    <div class="iq-breadcrumb-info">
      <div class="container">
        <div class="row">
          <div class="col-sm-12 col-md-7 align-self-center">
            <h2 class="iq-fw-8 mb-3">Blog</h2>
          </div>
        </div>
      </div>
    </div>
    <div class="iq-breadcrumb-img">
      <img src="/assets/images/breadcrumb/02.png" class="img-fluid breadcrumb-two" alt="image">
    </div>
  </section>
  <!-- Breadcrumb END -->
  <!-- Main Content -->
  <div class="main-content">
    <!-- Blogs -->
    <section class="iq-blogs">
      <div class="container">
        <div class="row ">
          <div class="col-lg-4">
            <div class="left-side-blog">
              <div class="iq-sidebar-widget">
                <div class="iq-widget-search position-relative">
                  <a href="javascript:void(0)" style="padding-top:15px">
                    <i class="fa fa-search"></i>
                  </a>
                  <input type="search" placeholder="Recherche" class="form-control placeholder search-btn">
                </div>
              </div>
              <h5 class="iq-widget-title iq-fw-8 mb-4">Articles récents</h5>
              <div class="iq-mt-80" v-for="articleRencents in articleRencent">
                <div class="media mb-3">
                  <img class="mr-3" src="/assets/images/blog/blog-detail/01.jpg" alt="image">
                  <div class="media-body">
                    <a href="">
                      <h6 class="mt-0 iq-fw-8">{{ articleRencents.titre }}</h6>
                    </a>
                    {{ convertDate(articleRencents.createdAt) }}
                  </div>
                </div>
              </div>
              <div class="iq-sidebar-widget iq-mt-80">
                <h5 class="iq-fw-8 mb-4">Catégories</h5>
                <ul class="d-block">
                  <li class="d-block mb-3" v-for="categorie in categories">
                    <a href="javascript:void(0)" class="iq-fw-5">
                      {{ categorie.libelle }}
                      <span>{{categorie.nbreArticles}}</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-lg-8 iq-rmt-40">
            <div class="row">
              <div class="col-lg-6" v-for="article in articles">
                <div class="main-blog">
                  <div class="blog-img">
                    <img src="/assets/images/blog/01.jpg" class="img-fluid" alt="image">
                  </div>
                  <div class="blog-detail">
                    <a class="main-color iq-fw-8" href="#">
                      {{ article.categorieID.libelle }}
                    </a>
                    <a href="#">
                      <h5 class="mt-1 iq-fw-8 text-center">
                        {{ article.titre }}
                      </h5>
                    </a>
                    <p class="mb-0 text-justify">
                      {{ article.sousTitre }}
                    </p>
                    <div class="blog-info text-center">
                      <ul class="d-inline-block pt-2">
                        {{ convertDate(article.createdAt) }}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-5">
              <div class="col-sm-12">
                <nav>
                  <ul class="pagination justify-content-center">
                    <li class="page-item">
                      <span class="page-link"><i class="fas fa-arrow-left"></i></span>
                    </li>
                    <li class="page-item active" aria-current="page">
                      <a class="page-link" href="javascript:void(0)">
                        1
                        <span class="sr-only">(current)</span>
                      </a>
                    </li>
                    <li class="page-item"><a class="page-link" href="javascript:void(0)">2</a></li>
                    <li class="page-item"><a class="page-link" href="javascript:void(0)">3</a></li>
                    <li class="page-item"><a class="page-link" href="javascript:void(0)">4</a></li>
                    <li class="page-item">
                      <a class="page-link" href="javascript:void(0)"><i class="fas fa-arrow-right"></i></a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>

        </div>
      </div>
    </section>
    <!-- Blogs END -->
  </div>
</template>

<script>
import $ from 'jquery';
import axios from "axios";
import moment from "moment";

export default {
  name: "BlogView.vue",

  data: function() {
    return {
      categories: [],
      articles: [],
      articleRencent:[],
      nbr: ""
    };
  },
  mounted: function() {
    axios
        .get('https://www.smartcard225.com:7001/api/v1/categorieArticles')
        .then(response => {
          console.log(response.data);
          this.categories = response.data.categorieArticles;
        })
        .catch(function (error) {
          console.log(error);
        })

    axios.get('https://www.smartcard225.com:7001/api/v1/articles')
        .then(response => {
          console.log("articles : "+ response.data);
          this.articles = response.data.articles;
        })

    axios.get('https://www.smartcard225.com:7001/api/v1/articles/latests')
        .then(response => {
          console.log("articleRencent : "+ response.data);
          this.articleRencent = response.data.articles;
        })

  },
  methods: {
    convertDate(x) {
      return moment(x).format("DD/MM/yyyy");
    }
  },


}

</script>

<style scoped>

</style>
