import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

import { Field, Form, ErrorMessage, defineRule } from 'vee-validate'
import { required, email } from '@vee-validate/rules'


defineRule('required', required)
defineRule('email', email)

createApp(App)
    .use(store)
    .use(router)
    .component('Field', Field)
    .component('Form', Form)
    .component('ErrorMessage', ErrorMessage)
    .mount('#app')
