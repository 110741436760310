import { createRouter, createWebHistory } from 'vue-router'

import AccueilView from "@/views/AccueilView";
import BlogView from "@/views/BlogView";
import ContactView from "@/views/ContactView";
import ErreurView from "@/views/ErreurView";
import AboutUseView from "@/views/AboutUseView";
import BlogDetailsView from "@/views/BlogDetailsView";
import CreateCarteView from "@/views/CreateCarteView";
import FaqView from "@/views/faqView";
import LoginView from "@/views/loginView";
import PasswordResetView from "@/views/PasswordResetView";
import PrivacyPolicyView from "@/views/PrivacyPolicyView";
import RegisterView from "@/views/RegisterView";

const routes = [
    {
        path: '/',
        name: 'home',
        component: AccueilView,
    },
    {
        path: '/blog',
        name: 'blog',
        component: BlogView,
    },
    {
        path: '/blog-details',
        name: 'blog-details',
        component: BlogDetailsView,
    },
    {
        path: '/contact',
        name: 'contact',
        component: ContactView,
    },
    {
        path: '/about',
        name: 'about',
        component: AboutUseView,
    },
    {
        path: '/create-card',
        name: 'create-card',
        component: CreateCarteView,
    },
    {
        path: '/faq',
        name: 'faq',
        component: FaqView,
    },
    {
        path: '/login',
        name: 'login',
        component: LoginView,
    },
    {
        path: '/maintenance',
        name: 'maintenance',
        component: LoginView,
    },
    {
        path: '/password-reset',
        name: 'password-reset',
        component: PasswordResetView,
    },
    {
        path: '/privacy-policy',
        name: 'privacy-policy',
        component: PrivacyPolicyView,
    },
    {
        path: '/register',
        name: 'register',
        component: RegisterView,
    },
    {
        path: '/404',
        name: 'erreur',
        component: ErreurView,
    },
    /*{
        path: '',
        redirect: '/'
    },*/
    /*{
        path: '/about',
        name: 'about',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/!* webpackChunkName: "about" *!/ '../views/AboutView.vue')
    }*/
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
