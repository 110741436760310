<template>
  <!-- loading -->
  <div id="loading">
    <div id="loading-center">
      <img src="/assets/images/loader.gif" alt="loder">
    </div>
  </div>
  <!-- loading End -->
  <div class="login-from header-navbar register-form light-gray-bg position-relative">
    <div class="row no-gutters">
      <div class="col-lg-6 align-items-stretch position-relative white-bg">
        <nav class="navbar navbar-expand-lg navbar-light">

          <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav ml-auto">
              <li class="nav-item">
                <a class="nav-link" href="index.html">Home</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="privacy-policy.html">Privacy</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="javascript:void(0)">Terms</a>
              </li>
            </ul>
          </div>
        </nav>
        <div class="login-info">
          <h2 class="iq-fw-8 mb-3">Register</h2>
          <h6>Welcome to <span class="main-color">Markethon</span> please Register your account.</h6>
          <form>
            <div class="form-group">
              <input type="email" class="form-control"   placeholder="Username">

            </div>
            <div class="form-group">
              <input type="email" class="form-control"   placeholder="Email Address">

            </div>

            <div class="form-group">
              <input type="password" class="form-control"  placeholder="Password">
            </div>
            <div class="form-group">
              <input type="password" class="form-control"  placeholder="Confirm Password">
            </div>
            <div class="form-group form-check mb-4">
              <input type="checkbox" class="form-check-input" id="exampleCheck1">
              <label class="form-check-label" for="exampleCheck1">By Clicking register, you agree on our term and condition</label>

            </div>
            <a class="slide-button button mr-3" href="register.html">
              <div class="first">Register</div>
              <div class="second">Register</div>
            </a>
            <a class="slide-button button bt-border" href="login.html">
              <div class="first">Login</div>
              <div class="second">Login</div>
            </a>
          </form>
        </div>
        <ul class="social-list">
          <li><a href="#"><i class="fab fa-facebook-f"></i></a></li>
          <li><a href="#"><i class="fab fa-twitter"></i></a></li>
          <li><a href="#"><i class="fab fa-google-plus-g"></i></a></li>
        </ul>
      </div>
      <div class="col-lg-6 align-self-center position-relative">
        <div class="login-right-bar h-100 text-center">
          <img src="/assets/images/login/register.png" class="img-fluid" alt="">
        </div>

      </div>
    </div>
    <img  src="/assets/images/login/2.png" class="img-fluid login-footer-one" alt="">
    <img  src="/assets/images/login/3.png" class="img-fluid login-footer-two" alt="">
    <img  src="/assets/images/login/1.png" class="img-fluid login-footer-three" alt="">
    <!-- back-to-top -->
    <div id="back-to-top">
      <a class="top" id="top" href="#top"><i class="ion-ios-arrow-thin-up"></i></a>
    </div>
    <!-- back-to-top End -->
  </div>
</template>

<script>
export default {
  name: "RegisterView.vue"
}
</script>

<style scoped>

</style>
