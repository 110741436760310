<template>
  <!-- menu start -->
  <nav id="menu-1" class="mega-menu" data-color="" >
    <!-- menu list items container -->
    <div class="menu-list-items">
      <div class="container-fluid">
        <div class="row">
          <div class="col-sm-12">
            <!-- menu logo -->
            <ul class="menu-logo">
              <li>
                <a href="/">
                  <img alt="logo" class="img-fluid" src="/assets/images/logo.png"/>
                </a>
              </li>
            </ul>
            <!-- menu search bar -->
            <ul class="menu-search-bar">
              <li class="menu-contact">
                <a data-target="#carteModal" data-toggle="modal">
                  <div
                      id="slide-3-layer-4"
                      class="tp-caption rev-btn button button-sm rev-withicon btn-commande tp-resizeme rs-hover-ready"
                      data-height="['auto']"
                      data-responsive_offset="on"
                      data-splitin="none"
                      data-splitout="none"
                      data-start="500"
                      data-style_hover="c:rgba(255, 255, 255, 1.00);bg:rgba(124, 96, 213, 1.00);"
                      data-transform_hover="o:1;rX:0;rY:0;rZ:0;z:0;s:0;e:Linear.easeNone;"
                      data-transform_idle="o:1;"
                      data-transform_in="x:50px;opacity:0;s:1000;e:Power4.easeOut;"
                      data-transform_out="opacity:0;s:300;e:Back.easeIn;"
                      data-width="['auto']"
                      data-x="33"
                      data-y="489"
                      style="
                        z-index: 7;
                        white-space: nowrap;
                        font-size: 18px;
                        line-height: 50px;
                        height: auto;
                        font-weight: 300;
                        color: rgba(255, 255, 255, 1);
                        font-family: Nunito;
                        padding: 0 35px 0 35px;
                        border-color: rgba(0, 0, 0, 1);
                        border-radius: 30px 30px 30px 30px;
                        outline: none;
                        box-shadow: none;
                        box-sizing: border-box;
                        -moz-box-sizing: border-box;
                        -webkit-box-sizing: border-box;
                        cursor: pointer;
                      "
                  >
                    MA CARTE
                  </div>
                </a>
              </li>
            </ul>
            <!-- menu links -->
            <ul class="menu-links">
              <!-- active class -->
              <li class="text-light">
                <a :class="{ active: isActive('home') }" href="/">Accueil</a>
              </li>
              <li class="text-light">
                <a :class="{ active: isActive('blog') }" href="/blog">Blog</a>
              </li>
              <li><a :class="{ active: isActive('contact') }" href="/contact">Nous contacter</a></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: "NavWiew.vue",
   methods: {
    isActive(routeName) {
      return this.$route.name === routeName;
    }
  }
}
</script>

<style scoped>

</style>
