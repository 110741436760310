<template>
  <div class="login-from header-navbar light-gray-bg position-relative">
    <div class="row no-gutters">
      <div class="col-lg-6 align-items-stretch position-relative white-bg">
        <nav class="navbar navbar-expand-lg navbar-light">
          <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav ml-auto">
              <li class="nav-item">
                <router-link class="nav-link" to="/">Accueil</router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" to="/blog">Blog</router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" to="/contact">Contacts</router-link>
              </li>
            </ul>
          </div>
        </nav>
        <div class="login-info" style="margin-top: 0px; margin-bottom:0px; padding-top: 50px;">
          <h2 class="iq-fw-9 mb-3">Connexion</h2>
          <h6>Bienvenue à <span class="main-color">SMARTCARD.</span><br>Veuillez vous connecter à votre compte.</h6>
          <form autocomplete="off">
            <div class="form-group">
              <input type="email" class="form-control" name="email" placeholder="Votre Email" required="required">
            </div>
            <div class="form-group">
              <input type="password" class="form-control" name="password" placeholder="Votre Mot de Passe" required="required">
            </div>
            <div class="form-group form-check">
              <a href="#" class="float-right iq-fw-6"><u>Mot de passe oublié ?</u></a>
            </div>
            <hr class="mt-2">
            <div class="text-center mt-3">
              <a class="button mr-3" href="#">
                Connexion
              </a>
            </div>
          </form>
        </div>
        <ul class="social-list">
          <li><a href="#"><i class="fab fa-facebook-f pt-2"></i></a></li>
          <li><a href="#"><i class="fab fa-linkedin pt-2"></i></a></li>
          <li><a href="#"><i class="fab fa-whatsapp pt-2"></i></a></li>
        </ul>
      </div>
      <div class="col-lg-6 d-none d-sm-block align-self-center position-relative" style="background-image: url('images/login/login.jpg')!important; background-size:cover; height:100vh;"></div>
    </div>
    <img  src="/assets/images/login/2.png" class="img-fluid login-footer-one" alt="">
    <img  src="/assets/images/login/3.png" class="img-fluid login-footer-two" alt="">
    <!-- back-to-top -->
    <div id="back-to-top">
      <a class="top" id="top" href="#top"><i class="ion-ios-arrow-thin-up"></i></a>
    </div>
    <!-- back-to-top End -->
  </div>
</template>

<script>
export default {
  name: "loginView.vue"
}
</script>

<style scoped>

</style>
